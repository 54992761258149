import React from "react";
import { Box } from "@mui/material";
import PageLayout from "./layouts/PageLayout";
import FrequenceAsked from "../components/FrequenceAsked";
import { useSelector } from "react-redux";
import { helpCenterSelector } from "../states/features/selectors";

const FaqPage = () => {
    // ############# Redux State #############
    const { faqs } = useSelector(helpCenterSelector);

    return (
        <>
            <PageLayout
                head={"Frequency asked questions"}
                subHead={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, sequi neque veritatis earum, nihil culpa."
                }
                sectionHd={"Frequency asked questions"}
                sectionSubHd={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. "
                }
            >
                <Box>
                    <FrequenceAsked faqs={faqs} />
                </Box>
            </PageLayout>
        </>
    );
};

export default FaqPage;
