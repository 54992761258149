import React from 'react';
import { NavLink as Link } from 'react-router-dom';

// =========== utilities ================
import logo from '../assets/media/images/logo.png';
import { colors } from '../assets/utils/constants';

// ========== MUI import =================
import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    Button,
    Stack,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { styled } from '@mui/system';
import { ManageAccounts, Subject, WindowOutlined } from '@mui/icons-material';
import SpacingContent from './SpacingContent';

const Navbar = () => {
    // ============ var ==============
    const leftNavItems = [
        // {
        //     id: 1,
        //     name: 'Home',
        //     link: '/',
        // },
        {
            id: 3,
            name: 'Blogs',
            link: '/blog',
        },
        {
            id: 2,
            name: 'About us',
            link: '/about-us',
        },
        {
            id: 4,
            name: 'Help center',
            link: '/help-center',
        },
    ];

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // ============ styled component ==============
    const Nav = styled(AppBar)({
        backgroundColor: colors.primary,
        boxShadow: 'none',
    });

    const ProductButton = styled(Box)({
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'transition': 'all ease-in-out .3s',
        'fontSize': 12,
        'borderRadius': 6,
        'padding': 5,
        'paddingLeft': 15,
        'paddingRight': 20,
        'display': 'flex',
        'alignItems': 'center',
        'textTransform': 'uppercase',
        'fontWeight': 'bold',
        '&:hover': {
            backgroundColor: colors.secondary,
            transition: 'all ease-in-out .3s',
            color: colors.primary,
            opacity: 0.8,
        },
    });
    const DrawerLink = styled(ListItemButton)({
        color: colors.secondary,
        fontWeight: 'bold',
    });

    const LogoContainer = styled(Box)(({ theme }) => ({
        // padding: 10,
        display: 'flex',
        alignItems: 'center',
        img: {
            width: '60px',
            height: '35',
            objectFit: 'contain',
        },
        [theme.breakpoints.down('md')]: {
            img: {
                width: '40px',
                height: '35',
                objectFit: 'contain',
            },
        },
    }));

    return (
        <React.Fragment>
            <Nav sx={{ py: { md: 1, xs: 1 } }}>
                <SpacingContent>
                    <Stack direction="row" justifyContent="space-between">
                        {/* ############### left nav ########### */}
                        <Toolbar sx={{ p: { md: `0 !important` } }}>
                            {/* ################ logo ############# */}
                            <Link to="/">
                                <LogoContainer>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        style={{
                                            padding: 2,
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                            borderRadius: 7,
                                            border: `2px solid ${colors.secondary}`,
                                            width: 30,
                                            height: 30,
                                            marginRight: 5,
                                            img: {
                                                objectFit: 'contain',
                                            },
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: colors.secondary,
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Wazawa Max
                                    </Typography>
                                </LogoContainer>
                            </Link>
                            {/* ############### nav links ########### */}
                            <Box
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    ml: 4,
                                }}
                            >
                                {leftNavItems.map((item) => (
                                    <Link key={item.id} to={item.link}>
                                        {({ isActive }) => (
                                            <Button
                                                sx={{
                                                    'color': '#fff',
                                                    'borderRadius': 1,
                                                    'textTransform':
                                                        'capitalize',
                                                    'borderBottom': isActive
                                                        ? `2px solid ${colors.bgColor5}`
                                                        : undefined,
                                                    'pb': 1,
                                                    '& :hover': {
                                                        color: colors.bgColor5,
                                                    },
                                                    '& .item': {
                                                        fontSize: 16,
                                                        minWidth: 60,
                                                        fontWeight: 'bold',
                                                        opacity: 0.9,
                                                    },
                                                }}
                                            >
                                                <Typography className="item">
                                                    {item.name}
                                                </Typography>
                                            </Button>
                                        )}
                                    </Link>
                                ))}
                            </Box>
                        </Toolbar>

                        {/* ############### right nav content ########### */}
                        <Toolbar sx={{ p: { md: `0 !important` } }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ display: { sm: 'none' } }}
                            >
                                {!mobileOpen ? (
                                    <Subject
                                        sx={{
                                            fontSize: 35,
                                            color: colors.secondary,
                                        }}
                                    />
                                ) : (
                                    <CloseOutlinedIcon
                                        sx={{
                                            fontSize: 35,
                                            color: colors.secondary,
                                        }}
                                    />
                                )}
                            </IconButton>
                            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <Box sx={{ mr: 2 }}>
                                    <Link to="/services">
                                        <ProductButton variant="contained">
                                            <WindowOutlined
                                                sx={{ width: 40 }}
                                            />
                                            Services
                                        </ProductButton>
                                    </Link>
                                </Box>
                                <Box>
                                    <Link to="/login">
                                        <ProductButton variant="contained">
                                            <ManageAccounts
                                                sx={{ width: 40 }}
                                            />
                                            Login
                                        </ProductButton>
                                    </Link>
                                </Box>
                            </Box>
                        </Toolbar>
                    </Stack>
                </SpacingContent>
            </Nav>

            {/* ############### MOBILE NAV ########### */}
            <Box component="nav" sx={{ position: 'relatives' }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        'display': { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: '100%',
                            backgroundColor: colors.primary,
                            color: '#fff',
                        },
                    }}
                >
                    <Box
                        onClick={handleDrawerToggle}
                        sx={{ textAlign: 'center' }}
                    >
                        <Divider />
                        <List>
                            {leftNavItems.map((item) => (
                                <Link key={item.id} to={item.link}>
                                    <ListItem disablePadding>
                                        <DrawerLink
                                            sx={{
                                                textAlign: 'left',
                                                marginLeft: 2,
                                            }}
                                        >
                                            <ListItemText primary={item.name} />
                                        </DrawerLink>
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                position: 'absolute',
                                bottom: 100,
                                left: 0,
                                right: 0,
                            }}
                        >
                            <Link to="/services">
                                <ProductButton variant="contained">
                                    <WindowOutlined sx={{ width: 40 }} />
                                    Services
                                </ProductButton>
                            </Link>
                            <Link to="/login">
                                <ProductButton variant="contained">
                                    <ManageAccounts sx={{ width: 40 }} />
                                    Login
                                </ProductButton>
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={{ position: 'absolute', right: 0, top: 15 }}>
                        <Button
                            sx={{
                                'color': '#fff',
                                'borderRadius': 10,
                                'textTransform': 'capitalize',
                                '& :hover': {
                                    color: colors.secondary,
                                },
                            }}
                            onClick={handleDrawerToggle}
                        >
                            <CloseOutlinedIcon
                                sx={{
                                    fontSize: 35,
                                    color: colors.secondary,
                                }}
                            />
                        </Button>
                    </Box>
                </Drawer>
            </Box>
        </React.Fragment>
    );
};

export default Navbar;
