import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { ArrowRightAltTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { strCapitalizeFirstChar } from '../../helpers/strHelper';

const BlogPostWidget2 = ({ blog }) => {
    return (
        <>
            <Box
                sx={{
                    height: 450,
                    overflow: 'hidden',
                    position: 'relative',
                    bgcolor: colors.primary,
                    borderRadius: 10,
                    // borderTopLeftRadius: 50,
                    // borderBottomRightRadius: 50,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bgcolor: `rgba(46, 119, 174, .4)`,
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            position: 'absolute',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                        }}
                    >
                        <Typography
                            sx={{ fontSize: 18, fontWeight: 'bold', pb: 1 }}
                        >
                            {strCapitalizeFirstChar(blog.title)}
                        </Typography>
                        <Typography noWrap sx={{ fontSize: 15, mb: 1 }}>
                            {blog.content}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <Typography sx={{ fontSize: 15 }}>
                                    <strong>@Author :</strong>{' '}
                                    {blog?.author?.first_name}
                                </Typography>
                                <Typography sx={{ fontSize: 15 }}>
                                    <strong>Posted :</strong>{' '}
                                    {moment(blog.created_at).format(
                                        'MMM Do YYYY, h:mm A',
                                    )}
                                </Typography>
                            </Box>
                            <Link to={`/blog/${blog.id}`}>
                                <Button
                                    size="small"
                                    sx={{
                                        '& .arrow': {
                                            transition: '.3s all ease-in-out',
                                        },
                                        ':hover': {
                                            '& .arrow': {
                                                mr: -2,
                                                transition:
                                                    '.3s all ease-in-out',
                                            },
                                        },
                                    }}
                                >
                                    <ArrowRightAltTwoTone
                                        className="arrow"
                                        sx={{ fontSize: 35 }}
                                    />
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: 450,
                        overflow: 'hidden',
                        borderRadius: 15,
                        borderTopLeftRadius: 80,
                        borderTopRightRadius: 80,
                        backgroundImage: `url(${blog.blog_image})`,
                        backgroundSize: `450px, 100%`,
                        backgroundPosition: 'center',
                    }}
                ></Box>
            </Box>
        </>
    );
};

export default BlogPostWidget2;
