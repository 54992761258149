import React from 'react';
import { ReadMore } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../assets/utils/constants';
import SpacingContent from './SpacingContent';

const ForOrganization = () => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: colors.secondary,
                    display: 'flex',
                    alignItems: 'center',
                    py: 5,
                }}
            >
                <SpacingContent maxPadding={2}>
                    <Grid container rowSpacing={4} columnSpacing={8}>
                        <Grid item sm={6}>
                            <Box sx={{ width: '100%' }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: { md: 25, xs: 20 },
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Wazawa Max for Organizations
                                    </Typography>
                                </Box>
                                <Box sx={{ py: 2 }}>
                                    <Typography>
                                        Don't let this opportunity pass you by;
                                        act right away to take full advantage of
                                        the exceptional solutions that are at
                                        your doorstep specifically for your
                                        business. Take use of our advertising
                                        services now, hire new employees right
                                        away, accept students for practical
                                        training right away, register as a
                                        supply entity or bidder right away, and
                                        cooperate with training institutions to
                                        help students to match with labour
                                        demands.
                                    </Typography>
                                </Box>
                                <Box sx={{ py: { md: 2, xs: 1 } }}>
                                    <Link to={'/for-organizations'}>
                                        <Button
                                            variant="contained"
                                            className="app-btn"
                                            endIcon={<ReadMore />}
                                            sx={{ textTransform: 'capitalize' }}
                                        >
                                            Read more
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box sx={{ width: '100%' }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: { md: 25, xs: 20 },
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Wazawa Max for students
                                    </Typography>
                                </Box>
                                <Box sx={{ py: 2 }}>
                                    <Typography>
                                        Getting started is now possible as the
                                        problem of obtaining a company for your
                                        Industrial Practical Training in order
                                        to satisfy academic criteria has been
                                        resolved.
                                    </Typography>
                                </Box>
                                <Box sx={{ py: { md: 2, xs: 1 } }}>
                                    <Link to={'/for-students'}>
                                        <Button
                                            variant="contained"
                                            className="app-btn"
                                            endIcon={<ReadMore />}
                                            sx={{ textTransform: 'capitalize' }}
                                        >
                                            Read more
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </SpacingContent>
            </Box>
        </>
    );
};

export default ForOrganization;
