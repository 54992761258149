import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PageLayout from '../layouts/PageLayout';
import { DoubleArrow, Window } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';
import StoreButtons from '../../components/StoreButtons';

const Policy = () => {
    return (
        <>
            <PageLayout
                head={'Service details'}
                subHead={
                    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, sequi neque veritatis earum, nihil culpa.'
                }
                sectionHd={'Fundi-client managements'}
                sectionSubHd={
                    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, sequi neque veritatis earum, nihil culpa.'
                }
            >
                <Grid container spacing={4} justifyContent={'space-around'}>
                    <Grid item sm={7}>
                        <Box
                            sx={{
                                width: '100%',
                                bgcolor: colors.primary,
                                borderRadius: 2,
                            }}
                        >
                            <Box sx={{ p: { md: 5, xs: 2 } }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: { md: 25, xs: 18 },
                                            fontWeight: 'bold',
                                            color: colors.secondary,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Window sx={{ mr: 1 }} />
                                        Fund-client management
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 2, color: '#eee' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            fontWeight: 100,
                                        }}
                                    >
                                        Lorem ipsum dolor sit amet consectetur,
                                        adipisicing elit. Cumque eius incidunt,
                                        odit deserunt vel rem tempora magni
                                        minima dolorem veniam, dolorum culpa
                                        quaerat? Vitae aliquam cum laudantium
                                        voluptas doloremque sapiente. Lorem
                                        ipsum dolor sit amet consectetur,
                                        adipisicing elit. Cumque eius incidunt,
                                        odit deserunt vel rem tempora magni
                                        minima dolorem veniam, dolorum culpa
                                        quaerat?
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 5 }}>
                                    <Typography
                                        sx={{
                                            color: colors.secondary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        What to benefit
                                    </Typography>
                                    <Box
                                        sx={{
                                            'color': '#eee',
                                            '& .item': {
                                                'fontSize': 14,
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'fontWeight': 100,
                                                'letterSpacing': 1.1,
                                                'mb': 1,
                                                '& .icon': {
                                                    mr: 1,
                                                    fontSize: 16,
                                                    color: colors.secondary,
                                                },
                                            },
                                        }}
                                    >
                                        <Typography className="item">
                                            <DoubleArrow className="icon" />
                                            Vitae aliquam Vitae aliquam cum
                                            laudantium voluptas doloremque
                                            sapiente. cum laudantium voluptas
                                            doloremque sapiente.
                                        </Typography>
                                        <Typography className="item">
                                            <DoubleArrow className="icon" />
                                            Vitae aliquam cum laudantium
                                            voluptas doloremque sapiente. cum
                                            laudantium voluptas doloremque
                                            sapiente.
                                        </Typography>
                                        <Typography className="item">
                                            <DoubleArrow className="icon" />
                                            Vitae aliquam cum laudantium
                                            voluptas doloremque sapiente
                                            sapiente.
                                        </Typography>
                                        <Typography className="item">
                                            <DoubleArrow className="icon" />
                                            Vitae aliquam cum laudantium
                                            voluptas doloremque sapiente. cum
                                            laudantium voluptas doloremque
                                        </Typography>
                                        <Typography className="item">
                                            <DoubleArrow className="icon" />
                                            Vitae aliquam voluptas doloremque
                                            sapiente. cum laudantium voluptas
                                            doloremque
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={5}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <StoreButtons />
                        </Box>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Policy;
