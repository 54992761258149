import React from 'react';
import {
    Box,
    Button,
    Grid,
    styled,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { colors } from '../assets/utils/constants';
import banner from '../assets/media/images/students.jpg';
import SpacingContent from '../components/SpacingContent';
import Section from '../components/Section';
import { ArrowRightAltRounded, Window } from '@mui/icons-material';
import ForServiceWidget from '../components/widgets/ForServiceWidget';

const MottoHead = styled(Typography)(({ theme }) => ({
    fontSize: 40,
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 'bold',
    color: colors.secondary,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
        fontSize: 25,
    },
}));

const ForOrganizations = () => {
    const deviceWidth = useMediaQuery('(min-width: 768px)');
    return (
        <>
            <Grid container sx={{ mt: 5 }}>
                <Grid item sm={6}>
                    <Box
                        sx={{
                            height: '70vh',
                            width: { md: '100%', xs: '100%' },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: `rgba(23, 35, 60, 1)`,
                        }}
                    >
                        <SpacingContent maxPadding={3}>
                            <Grid
                                container
                                justifyContent={{ md: 'center', xs: 'left' }}
                                spacing={3}
                            >
                                <Grid item sm={10} xs={12}>
                                    <Box
                                        sx={{
                                            textAlign: {
                                                md: 'left',
                                                xs: 'center',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                border: `2px solid ${colors.warning}`,
                                                width: 200,
                                                textAlign: 'center',
                                                p: 1,
                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary}) !important`,
                                                fontWeight: 'bold',
                                                fontSize: 18,
                                                textTransform: 'uppercase',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Window
                                                color="warning"
                                                sx={{ mr: 1 }}
                                            />
                                            For Students
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={10} xs={12}>
                                    <Box>
                                        <MottoHead>
                                            Being a student is easy. Learning
                                            requires actual work.
                                        </MottoHead>
                                    </Box>
                                </Grid>
                                <Grid item sm={10} xs={12}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                display: 'flex',
                                                color: colors.bgColor2,
                                            }}
                                        >
                                            Lorem ipsum dolor sit, amet
                                            consectetur. Lorem, ipsum dolor sit
                                            amet consectetur adipisicing elit.
                                            Reprehenderit voluptatibus sapiente
                                            omnis, voluptates necessitatibus
                                            officiis sint eius ea? Magnam autem.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={10} xs={12}>
                                    <Box>
                                        <a
                                            href={`${process.env.REACT_APP_STUDENT_URL}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    'pr': 10,
                                                    'fontSize': 18,
                                                    'background': `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                                                    '& .arrow': {
                                                        fontSize: 35,
                                                        ml: 2,
                                                        position: 'absolute',
                                                        right: 20,
                                                        transition: `.3s all ease-in-out`,
                                                    },
                                                    ':hover': {
                                                        '& .arrow': {
                                                            right: 10,
                                                            transition: `.3s all ease-in-out`,
                                                        },
                                                    },
                                                }}
                                            >
                                                Get started now
                                                <ArrowRightAltRounded className="arrow" />
                                            </Button>
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </SpacingContent>
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            height: { md: '70vh', xs: '100%' },
                            background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                        }}
                    >
                        <img
                            src={banner}
                            alt=""
                            style={{
                                height: deviceWidth ? '70vh' : 300,
                                width: '100%',
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Section>
                <SpacingContent maxPadding={2}>
                    <Grid container spacing={4} sx={{ my: 5 }}>
                        <Grid item sm={12}>
                            <Box>
                                <Typography className="section-head">
                                    What We offer for students
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        mt: 0.5,
                                        width: { md: '60%', xs: '100%' },
                                    }}
                                >
                                    Lorem ipsum, dolor sit amet consectetur
                                    adipisicing elit. Odit, tempora blanditiis!
                                    Modi enim dolores iusto sequi dolore itaque,
                                    provident in assumenda tenetur impedit quo
                                    corporis quaerat accusamus, excepturi quis
                                    perferendis?
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Grid container spacing={2}>
                                {[1, 2, 3].map((index) => (
                                    <Grid item sm={4} xs={12} key={index}>
                                        <ForServiceWidget />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </SpacingContent>
            </Section>
        </>
    );
};

export default ForOrganizations;
