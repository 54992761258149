import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import SpacingContent from "../components/SpacingContent";
import Image1 from "../assets/media/images/dsm.jpg";
import { colors } from "../assets/utils/constants";
import ContactWidget from "../components/widgets/ContactWidget";
import {
    Email,
    Phone,
    PinDropOutlined,
    Remove,
} from "@mui/icons-material";
import FAQs from "../components/FAQs";
import SocialAndSubscribe from "../components/SocialAndSubscribe";
import { helpCenterSelector } from "../states/features/selectors";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import MapLocation from "../components/MapLocation";
import SendUsEMail from "../components/SendUsEMail";

const Help = () => {
    // ############# Redux State #############
    const { contacts, faqs } = useSelector(helpCenterSelector);

    // => Convet contacts to array
    const restructureContacts = () => {
        let contactsObj = contacts[0];
        let contactsArr = [
            {
                id: 1,
                url: `tel:${contactsObj?.phone}`,
                icon: (
                    <Phone
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: "Call us",
                contact: isEmpty(contactsObj) ? <Remove /> : contactsObj?.phone,
            },
            {
                id: 4,
                url: `mailTo:${contactsObj?.email}`,
                icon: (
                    <Email
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: "Email us",
                contact: isEmpty(contactsObj) ? <Remove /> : contactsObj?.email,
            },
            {
                id: 3,
                url: `${contactsObj?.map_url}`,
                icon: (
                    <PinDropOutlined
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: "Main address",
                contact: isEmpty(contactsObj) ? (
                    <Remove />
                ) : (
                    contactsObj?.address
                ),
            },
        ];
        return contactsArr;
    };
    const contactsArr = restructureContacts();

    return (
        <>
            <Box
                sx={{
                    height: { md: 400, xs: 300 },
                    mb: { md: 30, xs: 80 },
                    backgroundImage: `url(${Image1})`,
                    backgroundPosition: "center",
                    backgroundSize: "100% 500px",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, rgba(23, 35, 60, .4), ${colors.bgColor2})`,
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <SpacingContent maxPadding={3}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: { md: 40, xs: 30 },
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    opacity: 0.9,
                                    textTransform: "uppercase",
                                }}
                            >
                                Get in touch
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { md: 40, xs: 30 },
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    opacity: 0.9,
                                    textTransform: "uppercase",
                                }}
                            >
                                with us
                            </Typography>
                        </Box>
                    </SpacingContent>
                </Box>
                <Box
                    sx={{
                        position: { md: "absolute", xs: "relative" },
                        left: 0,
                        right: 0,
                        top: { md: 130, xs: 200 },
                    }}
                >
                    <SpacingContent>
                        <Grid
                            container
                            rowSpacing={5}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <Grid item sm={7} xs={12}>
                                <Box
                                    sx={{
                                        height: { md: 300, xs: "100%" },
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderBottomLeftRadius: 50,
                                        background: `linear-gradient(180deg, rgba(23, 35, 60, 0), ${colors.secondary})`,
                                    }}
                                >
                                    <Grid container spacing={2} padding={2}>
                                        {contactsArr.map((contact) => (
                                            <Grid
                                                item
                                                sm={4}
                                                xs={4}
                                                key={contact.id}
                                            >
                                                <ContactWidget
                                                    contact={contact}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item sm={5} padding={2} xs={12}>
                                <SendUsEMail />
                            </Grid>
                        </Grid>
                    </SpacingContent>
                </Box>
            </Box>
            {!isEmpty(faqs) && (
                <Box sx={{ mb: 15 }}>
                    <FAQs />
                </Box>
            )}
            <Box>
                <SocialAndSubscribe />
            </Box>
            <Box>
                <MapLocation />
            </Box>
        </>
    );
};

export default Help;
