import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { blogsEndpoints } from './blogsEndpoints';

export const blogsAPI = createApi({
    reducerPath: 'blogsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
    }),
    endpoints: (builder) => ({
        // => GET all
        getBlogs: builder.query({
            query: () => `${blogsEndpoints.GET_ALL}`,
        }),
        // => GET one
        getBlog: builder.query({
            query: (payload) => `${blogsEndpoints.GET_ONE}/${payload}`,
        }),
    }),
});

export const { useGetBlogsQuery, useGetBlogQuery } = blogsAPI;
