import React from 'react';
import { Avatar, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Image1 from '../assets/media/images/dsm.jpg';
import mobApp from '../assets/media/images/mobApp.png';
import { colors } from '../assets/utils/constants';
import StoreButtons from '../components/StoreButtons';
import SpacingContent from '../components/SpacingContent';
import { RemoveRedEye, Route } from '@mui/icons-material';
import SocialAndSubscribe from '../components/SocialAndSubscribe';
import Slogan from '../components/Slogan';

const About = () => {
    const deviceWidth = useMediaQuery('(min-width: 768px)');
    return (
        <>
            <SpacingContent maxPadding={2}>
                <Box sx={{ mt: { md: 15, xs: 10 } }}>
                    <Grid
                        container
                        spacing={4}
                        justifyContent={'space-between'}
                    >
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 45,
                                            fontWeight: 'bold',
                                            opacity: 0.9,
                                            pl: 1,
                                            background: `linear-gradient(90deg,  ${colors.secondary},rgba(23, 35, 60, .0))`,
                                        }}
                                    >
                                        About Us
                                    </Typography>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 17,
                                                opacity: 0.8,
                                                mt: 2,
                                            }}
                                        >
                                            Wazawa Max is a multifaceted Network
                                            with superlative opportunities that
                                            aims to maximize or completely
                                            utilize the available opportunities
                                            to generate sustained
                                            social-economic impact through
                                            employment, entrepreneurship,
                                            education, environmental
                                            sustainability, and economic
                                            equality in Tanzania
                                        </Typography>
                                    </Box>
                                    {/* <Section> */}
                                    <Box
                                        sx={{
                                            'mt': { md: 8, xs: 4 },
                                            'display': 'flex',
                                            'justifyContent': 'space-between',
                                            '& .stats-con': {
                                                'width': '100%',
                                                'borderRadius': 2,
                                                'py': 1,
                                                'mx': 0.5,
                                                'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                                                '& .title': {
                                                    fontSize: {
                                                        md: 20,
                                                        xs: 16,
                                                    },
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    color: colors.primary,
                                                    opacity: 0.8,
                                                },
                                                '& .count': {
                                                    fontSize: {
                                                        md: 30,
                                                        xs: 25,
                                                    },
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                },
                                            },
                                        }}
                                    >
                                        <Box className="stats-con">
                                            <Typography
                                                className="count"
                                                sx={{
                                                    color: colors.warning,
                                                }}
                                            >
                                                50+
                                            </Typography>
                                            <Typography className="title">
                                                Organizations
                                            </Typography>
                                        </Box>
                                        <Box className="stats-con">
                                            <Typography
                                                className="count"
                                                sx={{
                                                    color: colors.success,
                                                }}
                                            >
                                                50+
                                            </Typography>
                                            <Typography className="title">
                                                Jobs vacancy
                                            </Typography>
                                        </Box>
                                        <Box className="stats-con">
                                            <Typography
                                                className="count"
                                                sx={{
                                                    color: colors.linkHover,
                                                }}
                                            >
                                                50+
                                            </Typography>
                                            <Typography className="title">
                                                Fundi's
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={5.5} xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    borderRadius: 150,
                                    borderBottomLeftRadius: 15,
                                    borderTopRightRadius: {
                                        md: 100,
                                        xs: 25,
                                    },
                                    boxShadow: `5px 5px 30px 0px ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                }}
                            >
                                <img
                                    src={Image1}
                                    alt=""
                                    style={{
                                        height: deviceWidth ? 600 : 250,
                                        width: '100%',
                                        borderRadius: deviceWidth ? 150 : 50,
                                        borderBottomLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ py: 10 }}>
                    <Grid container spacing={10} alignItems={'center'}>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            sx={{ display: { md: 'block', xs: 'none' } }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    borderRadius: 150,
                                    borderBottomLeftRadius: 15,
                                    borderTopRightRadius: {
                                        md: 100,
                                        xs: 25,
                                    },
                                    boxShadow: `5px 5px 30px 0px ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                }}
                            >
                                <img
                                    src={Image1}
                                    alt=""
                                    style={{
                                        height: deviceWidth ? 600 : 250,
                                        width: '100%',
                                        borderRadius: deviceWidth ? 150 : 50,
                                        borderBottomLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Slogan />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ pb: 10, pt: 0 }}>
                    <Grid container spacing={4}>
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    pb: 2,
                                    borderBottom: `15px solid ${colors.bgColor3}`,
                                }}
                            >
                                <Box>
                                    <RemoveRedEye
                                        sx={{ fontSize: 35 }}
                                        color="info"
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 35,
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                        }}
                                    >
                                        Our vision
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>
                                        "Wazawa Max envisions a prosperous
                                        Tanzania where our multifaceted network
                                        maximizes opportunities for sustainable
                                        socioeconomic impact, harmonizing
                                        employment, entrepreneurship, education,
                                        sustainability, and equality."
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    pb: 2,
                                    borderBottom: `15px solid ${colors.bgColor3}`,
                                }}
                            >
                                <Box>
                                    <Route sx={{ fontSize: 35 }} color="info" />
                                    <Typography
                                        sx={{
                                            fontSize: 35,
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                        }}
                                    >
                                        Our mission
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>
                                        "Our goal at Wazawa Max is to foster
                                        lasting socioeconomic development in
                                        Tanzania. Through innovation and
                                        collaboration, we champion equality,
                                        entrepreneurship, employment,
                                        sustainability, and education to enhance
                                        lives and our nation's well-being."
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ mb: 10 }}>
                    <Grid
                        container
                        spacing={4}
                        justifyContent={'space-between'}
                    >
                        <Grid item sm={5.5} xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    borderRadius: 150,
                                    borderBottomLeftRadius: 15,
                                    borderTopRightRadius: 100,
                                    boxShadow: `5px 5px 30px 0px ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                }}
                            >
                                {/* <img
                                    src={mobApp}
                                    alt=""
                                    style={{
                                        height: { md: 600, xs: 400 },
                                        width: '100%',
                                        borderRadius: 150,
                                        borderBottomLeftRadius: 15,
                                        borderTopRightRadius: 100,
                                    }}
                                /> */}
                                <Avatar
                                    src={mobApp}
                                    sx={{
                                        height: { md: '90vh', xs: '100%' },
                                        width: '100%',
                                        img: {
                                            objectFit: {
                                                md: 'cover',
                                                xs: 'contain',
                                            },
                                        },
                                    }}
                                    variant="square"
                                />
                            </Box>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: { md: 45, xs: 35 },
                                            fontWeight: 'bold',
                                            opacity: 0.9,
                                            pl: 1,
                                            background: `linear-gradient(90deg,  ${colors.secondary},rgba(23, 35, 60, .0))`,
                                        }}
                                    >
                                        Wazawa Max App
                                    </Typography>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 17,
                                                opacity: 0.8,
                                                mt: 2,
                                            }}
                                        >
                                            Enjoy using the Wazawa Max Blog as
                                            your entry point to the newest
                                            trends and success tales! Our
                                            website now has a lively blog that
                                            informs potential employees and
                                            employers about all the latest
                                            trends in the Wazawa Max network.
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 17,
                                                opacity: 0.8,
                                                mt: 2,
                                            }}
                                        >
                                            Discover a wealth of informative
                                            information, such as job seekers'
                                            endorsements, motivating success
                                            stories of students who profited
                                            from our network, and profiles of
                                            innovative businesses using in-app
                                            advertising spaces to reach their
                                            target customers in real time.
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 17,
                                                opacity: 0.8,
                                                mt: 2,
                                            }}
                                        >
                                            Stay informed, get inspired, and be
                                            part of the Wazawa Max community
                                            through our vibrant blog. It's your
                                            source for fresh insights, success
                                            stories, and the latest happenings
                                            in the world of talent acquisition
                                            and in-app advertising.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <StoreButtons />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </SpacingContent>
            <Box>
                <SocialAndSubscribe />
            </Box>
        </>
    );
};

export default About;
