import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Section from "./Section";
import { ArrowRightAltTwoTone } from "@mui/icons-material";
import SpacingContent from "./SpacingContent";
import CustomSwiper from "./swipers/CustomSwiper";
import { SwiperSlide } from "swiper/react";
import BlogPostWidget from "./widgets/BlogPostWidget";
import BlogPostWidget2 from "./widgets/BlogPostWidget2";
import { colors } from "../assets/utils/constants";
import { Link } from "react-router-dom";
import { blogsSelector } from "../states/features/selectors";
import { useSelector } from "react-redux";
import { isEmpty, shuffle, take } from "lodash";
import NoContent from "./NoContent";

const LatestBlogPost = () => {
    // ############## Redux state ############
    const { blogs } = useSelector(blogsSelector);

    return (
        <>
            <SpacingContent maxPadding={3}>
                <Section>
                    {isEmpty(blogs) ? (
                        <NoContent message={"No blog posted"} />
                    ) : (
                        <Grid
                            container
                            sx={{ mt: { md: "", xs: 5, position: "relative" } }}
                        >
                            <Grid item sm={12}>
                                <Box>
                                    <Typography className="section-head">
                                        Latest blogs
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            mt: 0.5,
                                        }}
                                    >
                                        Get update on every step we take.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            display: "flex",
                                            width: { md: "50%", xs: "100%" },
                                        }}
                                    >
                                        Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Pariatur dicta, et a
                                        id corporis nostrum veritatis
                                        necessitatibus asperiores maxime,
                                        consequatur maiores est corrupti
                                        exercitationem illo vitae itaque eius
                                        mollitia nisi.
                                    </Typography>
                                </Box>
                                <Grid container spacing={2} sx={{ mt: 0 }}>
                                    <Grid
                                        item
                                        sm={5}
                                        xs={12}
                                        sx={{
                                            display: {
                                                md: "block",
                                                xs: "none",
                                            },
                                        }}
                                    >
                                        <BlogPostWidget2
                                            blog={take(shuffle(blogs), 1)[0]}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={7}
                                        sx={{
                                            height: 450,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item sm={12}>
                                                <CustomSwiper element={"blog"}>
                                                    {blogs.map((blog) => (
                                                        <SwiperSlide
                                                            key={blog.id}
                                                        >
                                                            <BlogPostWidget
                                                                blog={blog}
                                                            />
                                                        </SwiperSlide>
                                                    ))}
                                                </CustomSwiper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: { md: 30, xs: 0 },
                                    right: { md: 10, xs: -5 },
                                    zIndex: 999,
                                }}
                            >
                                <Link to={"/blog"}>
                                    <Button
                                        endIcon={
                                            <ArrowRightAltTwoTone
                                                className="arrow"
                                                sx={{ fontSize: 35 }}
                                            />
                                        }
                                        color="secondary"
                                        variant="contained"
                                        sx={{
                                            fontSize: 15,
                                            fontWeight: "bold",
                                            color: colors.primary,
                                            "& .arrow": {
                                                transition:
                                                    ".3s all ease-in-out",
                                            },
                                        }}
                                    >
                                        More
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                    )}
                </Section>
            </SpacingContent>
        </>
    );
};

export default LatestBlogPost;
