import { Grid } from '@mui/material';
import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { colors } from '../../assets/utils/constants';
import { Work } from '@mui/icons-material';
import ServiceWidget from '../../components/widgets/ServiceWidget';

const Products = () => {
    const services = [
        {
            id: 1,
            service: 'Talent Tap Network',
            desc:
                "'Wazawa Max's Talent Tap Network is aplatform or network designed to access,connect, and use a range of talents andskills, particularly those obtained byyouths TVET, Higher learning institutionsas well as from informal educationalsystems in Tanzania. However, thenetwork is designed to be a locationwhere people, groups, firms andcompanies with a range of skills andexpertise are located and recruited bythose looking for their services, knowledge or skills using digital innovations. Don't miss it, enjoy thenetwork, and let's get started!",
            item: 4,
            icon: <Work sx={{ fontSize: 25, color: colors.primary }} />,
        },
        {
            id: 2,
            service: 'Max Skill Nexus',
            desc:
                "Wazawa Max's 'MAX Skills Nexus'network, different workforces arecompletely utilized, maximized, andnetworked to their highest potentialemployers and supply organizations. Itentails the existence of a sizable and wellorganized network of professionals andtenderers where individuals, groups, andorganizations with a variety of expertiseand product or service providers areidentified and linked with regular people,organizations, and corporate leaderswho need them. However, this network can also be viewed from the perspective of employers, who will use the superb Wazawa Max Network to perform personnel recruitment at their doorsteps. Don't miss it; get going right away to take full advantage of the innovation created just for you.",
            item: 4,
            icon: <Work sx={{ fontSize: 25, color: colors.primary }} />,
        },
        {
            id: 3,
            service: 'SkillAttach360',
            desc:
                'Wazawa Max’s "SkillAttach360" is a network where students from TVET and Higher Learning Institutions can get industrial practical training as well as be fully explored and used by companies during practical training sessions in various contexts and opportunities. However, "SkillAttach360" implies a comprehensive and all-encompassing method in which educational institutions and employers collaborate to shape students in order to match their competencies with relevant demands from all sides, and thus their dreams are connected to reality. Getting started is now possible as the problem of obtaining fieldwork experience in order to satisfy academic criteria has been resolved.',
            item: 3,
            icon: <Work sx={{ fontSize: 25, color: colors.primary }} />,
        },
        {
            id: 4,
            service: 'Career Crafters Network',
            desc:
                "Wazawa Max's 'Career Crafters Network' brings together people, businesses, and organizations with expertise and skills to help shape and nurture careers using a variety of strategies, including workshops, training, dialogues, festivals, and more. It is a strong network between industries, TVET, and Higher Learning Institutions for enhanced workforce readiness. Through the 'IndustryConnect' campaign, it implies to a group of professionals who are committed to advising and helping others develop and advance their careers.",
            item: 3,
            icon: <Work sx={{ fontSize: 25, color: colors.primary }} />,
        },
        {
            id: 5,
            service: 'Market Mingle',
            desc:
                'Wazawa Max’s A network called "Market Mingle" brings together, interacts with, or blends in some way manufacturers, businesses, enterprises, and individuals operating in a marketplace. It entails establishing connections with customers and sellers and making the most of the chances that arise in a commercial or corporate situation. Here, real-time communication between buyers and sellers will take place based on GPS-enhanced position data.',
            item: 3,
            icon: <Work sx={{ fontSize: 25, color: colors.primary }} />,
        },
        {
            id: 6,
            service: 'PromoConnect360',
            desc:
                "Wazawa Max’s 'PromoConnect360' is the service brand that ensures people, groups, organizations, enterprises, and businesses utilize their target market through Wazawa Max's PromoConnect360 by providing a wide range of promotional services. Advertisers will use the Wazawa Max Network in this case to secure in-app spaces to market their products and services to the general public since we are ready to manage all facets of promotional tactics, from planning and execution to analysis and optimization.",
            item: 3,
            icon: <Work sx={{ fontSize: 25, color: colors.primary }} />,
        },
    ];

    return (
        <>
            <PageLayout
                head={'Wazawa Max Opportunities'}
                // subHead={
                //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, sequi neque veritatis earum, nihil culpa.'
                // }
                sectionHd={'Wazawa Max services'}
                sectionSubHd={
                    'More than just a catchy phrase, "Connecting Dreams to Reality" embodies the essence of Wazawa Max. It stands for our purpose, commitment to excellence, and passion to supporting people and organizations in achieving their goals. The following Wazawa Max Opportunities are guided by this philosophy..'
                }
            >
                <Grid container spacing={4}>
                    {services.map((service) => (
                        <Grid item sm={4} key={service.id}>
                            <ServiceWidget service={service} />
                        </Grid>
                    ))}
                </Grid>
            </PageLayout>
        </>
    );
};

export default Products;
