import React from "react";
import { isEmpty } from "lodash";
import { Box, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Facebook, Instagram, Twitter, WhatsApp } from "@mui/icons-material";

const SocialLinkWidget = ({ link }) => {
    return (
        <>
            <a href={link.link} target="_blank" rel="noopener noreferrer">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 5,
                        overflow: "hidden",
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        border: `3px solid ${colors.bgColor4}`,
                        "& .title": {
                            fontSize: 14,
                            fontWeight: "bold",
                        },
                        "& .icon-con": {
                            height: 45,
                            width: 45,
                            borderRadius: 50,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mr: 1,
                            transition: `.3s all ease-in-out`,
                            "& .icon": {
                                fontSize: 30,
                                // color: link.iconColor,
                            },
                        },
                        ":hover": {
                            "& .icon-con": {
                                transform: `scale(1.3)`,
                                transition: `.3s all ease-in-out`,
                            },
                        },
                    }}
                >
                    {!isEmpty(link) && (
                        <Box className={"icon-con"}>
                            {link.name.toLowerCase() === "whatsapp" ? (
                                <WhatsApp className="icon" color="success" />
                            ) : link.name.toLowerCase() === "facebook" ? (
                                <Facebook className="icon" color="info" />
                            ) : link.name.toLowerCase() === "instagram" ? (
                                <Instagram className="icon" color="error" />
                            ) : link.name.toLowerCase() === "twitter" ? (
                                <Twitter className="icon" color="info" />
                            ) : (
                                ""
                            )}
                        </Box>
                    )}

                    <Typography className="title">{link.name}</Typography>
                </Box>
            </a>
        </>
    );
};

export default SocialLinkWidget;
