import { ReadMore } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';

const ServiceWidget = ({ service }) => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: colors.bgColor3,
                    borderTopLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    overflow: 'hidden',
                    pb: 2,
                    height: '100%',
                    border: `2px solid ${colors.bgColor4}`,
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary}) !important`,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            opacity: 0.7,
                            borderTop: `5px solid ${colors.primary}`,
                            width: 30,
                            height: 30,
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {service.icon}
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                mt: 1,
                                color: colors.primary,
                                opacity: 0.7,
                            }}
                        >
                            {service.service}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography sx={{ fontSize: '15px' }}>
                        {`${service.desc.substr(0, 190)} . . .`}
                    </Typography>
                </Box>
                <Box
                    sx={{ px: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Link to={'/services/details'}>
                        <Button
                            variant="outlined"
                            size="small"
                            className="app-btn"
                            endIcon={<ReadMore />}
                            sx={{
                                mr: 2,
                                textTransform: 'capitalize',
                            }}
                        >
                            Read more
                        </Button>
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default ServiceWidget;
