import React from 'react';
import { Box, Typography } from '@mui/material';
import PageLayout from './layouts/PageLayout';
import { ArrowRight } from '@mui/icons-material';
import { colors } from '../assets/utils/constants';

const Policy = () => {
    const policies = [
        {
            id: 1,
            policy: 'Information We Collect',
            description:
                'We may collect the following types of information from stakeholders:',
            points: [
                {
                    id: 1,
                    point:
                        'Personal Information: This includes your name, email address, phone number, and other identifiers necessary for account creation and communication.',
                },
                {
                    id: 2,
                    point:
                        'Usage Information: We collect information about your use of our network, including your interactions with our services, content, and advertisements.',
                },
                {
                    id: 3,
                    point:
                        'Device Information: We may collect device information such as your device type, operating system, and unique device identifiers.',
                },
                {
                    id: 4,
                    point:
                        'Location Information: With your permission, we may collect your location information based on GPS-enhanced position data for services like Market Mingle.',
                },
            ],
        },
        {
            id: 2,
            policy: 'How We Use Your Information',
            description:
                'We use the collected information for the following purposes:',
            points: [
                {
                    id: 1,
                    point:
                        'To provide and improve our services, including Talent Tap Network, Max Skill Nexus, SkillAttach360, Career Crafters Network, Market Mingle, and PromoConnect360.',
                },
                {
                    id: 2,
                    point:
                        'To personalize your experience on our network and show you relevant content and advertisements.',
                },
                {
                    id: 3,
                    point:
                        'To communicate with you, respond to your inquiries, and send updates about our network and services.',
                },
                {
                    id: 4,
                    point:
                        'To ensure the security and integrity of our network and protect against unauthorized access or use.',
                },
            ],
        },
        {
            id: 3,
            policy: 'Sharing of Your Information',
            description: 'We may share your information in the following ways:',
            points: [
                {
                    id: 1,
                    point:
                        'With Service Providers: We may share your information with third-party service providers who assist us in providing and maintaining our services.',
                },
                {
                    id: 1,
                    point:
                        'With Advertisers: In the case of PromoConnect360, we may share your information with advertisers to facilitate targeted advertisements.',
                },
                {
                    id: 1,
                    point:
                        'With Other Stakeholders: Some features of our network involve interactions between stakeholders. In such cases, certain information may be shared between stakeholders to facilitate these interactions.',
                },
                {
                    id: 1,
                    point:
                        'Legal Obligations: We may disclose your information to comply with legal obligations, including responding to lawful requests and court orders.',
                },
            ],
        },
        {
            id: 4,
            policy: 'Your Choices',
            description:
                'You have the following choices regarding your information:',
            points: [
                {
                    id: 1,
                    point:
                        'Account Information: You can update or delete your account information by accessing your account settings.',
                },
                {
                    id: 1,
                    point:
                        'Location Information: You can control location-sharing preferences through your device settings.',
                },
                {
                    id: 1,
                    point:
                        'Communication Preferences: You can manage your communication preferences by unsubscribing from promotional emails or notifications.',
                },
            ],
        },
        {
            id: 5,
            policy: 'Security',
            description:
                'We take reasonable measures to protect your information, but no data transmission over the internet can be guaranteed to be completely secure. We cannot guarantee the security of any information you transmit to us or that is stored on our network.',
            points: [],
        },
        {
            id: 6,
            policy: 'Changes to this Privacy Policy',
            description:
                'We reserve the right to update or modify this Privacy Policy at any time. We will notify you of significant changes through prominent notices on our network or via email.',
            points: [],
        },
        {
            id: 7,
            policy: 'Contact Us',
            description:
                'If you have questions or concerns about this Privacy Policy or our practices, please contact us at [Contact Information].',
            points: [],
        },
    ];
    return (
        <>
            <PageLayout
                head={'Privacy Policy for Wazawa Max Network Stakeholders'}
                sectionHd={'Privacy policy'}
                sectionSubHd={
                    <Box sx={{ mb: 5 }}>
                        <Typography
                            sx={{
                                fontSize: 16,
                                opacity: 0.7,
                                color: colors.warning,
                            }}
                        >
                            Wazawa Max Network ("Wazawa Max," "we," "us," or
                            "our") is committed to safeguarding your privacy.
                            This Privacy Policy outlines how we collect, use,
                            disclose, and protect your personal information as a
                            stakeholder participating in our multifaceted
                            network. By accessing and using the Wazawa Max
                            Network, you consent to the practices described in
                            this Privacy Policy.
                        </Typography>
                    </Box>
                }
            >
                <Box sx={{ mt: 2 }}>
                    {policies.map((policy, index) => (
                        <Box key={index} sx={{ mb: 5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 35,
                                        width: 35,
                                        justifyContent: 'center',
                                        border: `1px dotted ${colors.warning}`,
                                        borderRadius: 2,
                                        mr: 1,
                                        color: colors.warning,
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {++index}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        opacity: 0.6,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {policy.policy}
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    opacity: 0.6,
                                    py: 1,
                                    fontWeight:
                                        policy.points.length > 0
                                            ? 'bold'
                                            : 'default',
                                }}
                            >
                                {policy.description}
                            </Typography>
                            {policy.points.map((point, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        mb: 1,
                                        fontSize: 16,
                                        opacity: 0.8,
                                    }}
                                >
                                    <ArrowRight
                                        sx={{
                                            mt: 0.2,
                                        }}
                                    />
                                    {point.point}
                                </Typography>
                            ))}
                        </Box>
                    ))}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                opacity: 0.6,
                                textAlign: 'center',
                            }}
                        >
                            By using the Wazawa Max Network, you acknowledge
                            that you have read, understood, and agreed to this
                            Privacy Policy. <br />
                        </Typography>
                        <Typography
                            sx={{
                                pt: 4,
                                pb: 1,
                                fontSize: { md: 22, xs: 18 },
                                fontWeight: 'bold',
                                opacity: 0.6,
                                color: colors.warning,
                                textAlign: 'center',
                            }}
                        >
                            Last Updated: 10th September 2023
                        </Typography>
                    </Box>
                </Box>
            </PageLayout>
        </>
    );
};

export default Policy;
