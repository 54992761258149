import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    blogs: [],
    blogDetails: {},
};

// !########## SLICE #############
const blogsSlice = createSlice({
    name: 'blogsSlice',
    initialState,
    reducers: {
        setBlogs: (state, actions) => {
            state.blogs = actions.payload.data;
        },
        setBlogDetails: (state, actions) => {
            state.blogDetails = actions.payload;
        },
    },
});

export const { setBlogs, setBlogDetails } = blogsSlice.actions;
export default blogsSlice.reducer;
