import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { colors } from '../assets/utils/constants';
import StoreButtons from './StoreButtons';
import Image1 from '../assets/media/images/dsm.jpg';
import bnImg from '../assets/media/images/banner2.png';
import mob from '../assets/media/images/mobApp.png';
import logo from '../assets/media/images/logo.png';
import SpacingContent from './SpacingContent';
import { Window } from '@mui/icons-material';

const Banner = () => {
    return (
        <>
            <Box
                sx={{
                    height: '100vh',
                    display: { md: 'flex', xs: 'block' },
                    backgroundImage: `url(${Image1})`,
                }}
            >
                <Box
                    sx={{
                        height: '100vh',
                        width: { md: '50%', xs: '100%' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: {
                            md: `rgba(23, 35, 60, 1)`,
                            xs: `rgba(23, 35, 60, .8)`,
                        },
                        position: 'relative',
                    }}
                >
                    <SpacingContent maxPadding={3}>
                        <Grid
                            container
                            justifyContent={'center'}
                            rowSpacing={2}
                        >
                            <Grid item sm={10} xs={12} sx={{ mt: 15 }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            border: `2px solid ${colors.warning}`,
                                            width: 200,
                                            textAlign: 'center',
                                            p: 1,
                                            mb: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary}) !important`,
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            textTransform: 'uppercase',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Window
                                            color="warning"
                                            sx={{ mr: 1 }}
                                        />
                                        Wazawa MAX
                                    </Typography>
                                    <Typography
                                        className="hea"
                                        noWrap
                                        sx={{
                                            fontSize: { md: 55, xs: 35 },
                                            color: colors.secondary,
                                            lineHeight: { md: 1, xs: 1.2 },
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Connecting
                                    </Typography>
                                    <Typography
                                        className="hea"
                                        // noWrap
                                        sx={{
                                            fontSize: { md: 55, xs: 35 },
                                            color: colors.secondary,
                                            lineHeight: { md: 1, xs: 1.2 },
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Dreams to Reality
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={10} xs={12}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        borderRadius: 150,
                                        borderBottomLeftRadius: 15,
                                        borderTopRightRadius: {
                                            md: 100,
                                            xs: 25,
                                        },
                                        display: { md: 'none', xs: 'none' },
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        rigth: 0,
                                        opacity: 0.2,
                                    }}
                                >
                                    <img
                                        src={bnImg}
                                        alt=""
                                        style={{
                                            height: 400,
                                            width: '100%',
                                            borderRadius: 50,
                                            borderBottomLeftRadius: 15,
                                            borderTopRightRadius: 15,
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item sm={10} xs={12}>
                                <Box>
                                    <Typography
                                        sx={{
                                            textAlign: {
                                                md: 'left',
                                                xs: 'left',
                                            },
                                            color: colors.bgColor2,
                                            fontSize: 16,
                                        }}
                                    >
                                        Join Wazawa Max, where dreams meet
                                        reality and where students can find
                                        potential opportunities, companies can
                                        find talent, customers can interact with
                                        businesses, and fresh tender
                                        opportunities are always available.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={10} xs={12}>
                                <Box>
                                    <StoreButtons />
                                </Box>
                            </Grid>
                        </Grid>
                    </SpacingContent>
                </Box>
                <Box
                    sx={{
                        height: '100vh',
                        width: '50%',
                        display: { md: 'flex', xs: 'none' },
                        bgcolor: `rgba(23, 35, 60, 1)`,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            mt: 20,
                            height: 300,
                            width: 300,
                            borderRadius: 50,
                            bgcolor: colors.primary,
                            position: 'absolute',
                            zIndex: 500,
                            opacity: 0.6,
                            boxShadow: `0px 0px 80px 80px ${colors.secondary}, 0px 0px 80px 80px ${colors.primary}`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            src={logo}
                            sx={{
                                height: '100%',
                                width: '100%',
                                overflow: 'hidden',
                                borderRadius: 50,
                                background: `transparent`,
                                zIndex: 999,
                                img: {
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                },
                            }}
                            variant="square"
                        />
                    </Box>
                    <Avatar
                        src={mob}
                        sx={{
                            height: '100%',
                            width: '100%',
                            background: `transparent`,
                            zIndex: 999,
                            img: {
                                mt: 12,
                                height: '90%',
                                width: '90%',
                                objectFit: 'contain',
                            },
                        }}
                        variant="square"
                    />
                </Box>
            </Box>
        </>
    );
};

export default Banner;
