import { WorkHistory } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

const ForServiceWidget = () => {
    return (
        <>
            <Box
                sx={{
                    widthL: '100%',
                    p: 3,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                }}
            >
                <WorkHistory sx={{ fontSize: 45 }} />
                <Typography sx={{ fontSize: 25, fontWeight: 'bold' }}>
                    Job posting
                </Typography>
                <Typography>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Numquam maxime distinctio at quis autem, reprehenderit
                    soluta, a unde, ipsam placeat non dolore vel dolores
                    molestias facere officiis nobis est recusandae.
                </Typography>
            </Box>
        </>
    );
};

export default ForServiceWidget;
