import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import SpacingContent from "../components/SpacingContent";
import { colors } from "../assets/utils/constants";
import PageLayout2 from "./layouts/PageLayout2";
import useRTK from "../hooks/useRTK";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { blogsSelector } from "../states/features/selectors";
import { setBlogDetails } from "../states/features/blogs/blogsSlice";
import { useGetBlogQuery } from "../api/blogs/blogsAPI";
import PageLoader from "../components/PageLoader";
import InnerDataFetchingError from "../components/InnerDataFetchingError";
import moment from "moment";
import LatestBlogPost from "../components/LatestBlogPost";
import { isEmpty } from "lodash";

const PostDetails = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { post_id } = useParams();
    const deviceWidth = useMediaQuery("(min-width: 768px)");

    // ############# Comp State ############
    const { blogDetails: blog } = useSelector(blogsSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: responseData,
    } = useGetBlogQuery(post_id);
    const rtkAction = (data) => {
        dispatch(setBlogDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkAction);
    return (
        <>
            {isLoading ? (
                <>
                    <PageLoader />
                </>
            ) : isError ? (
                <>
                    <InnerDataFetchingError
                        height={"100vh"}
                        handleDataRefetch={refetch}
                    />
                </>
            ) : (
                <PageLayout2 head={blog?.title}>
                    {!isEmpty(blog) && (
                        <SpacingContent maxPadding={2}>
                            <Box
                                sx={{
                                    mt: { md: -10, xs: -10 },
                                    mb: 10,
                                    borderRadius: { md: 15, xs: 5 },
                                    overflow: "hidden",
                                    pb: 4,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                                }}
                            >
                                <Grid container spacing={4}>
                                    <Grid item sm={5} xs={12}>
                                        <Box
                                            sx={{
                                                height: { md: 400, xs: 200 },
                                                p: 2,
                                            }}
                                        >
                                            <img
                                                src={blog.blog_image}
                                                alt=""
                                                style={{
                                                    width: "100%",
                                                    borderRadius: deviceWidth
                                                        ? 50
                                                        : 25,
                                                    height: deviceWidth
                                                        ? 400
                                                        : 200,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={7} xs={12}>
                                        <Box sx={{ p: 2 }}>
                                            <Typography
                                                sx={{
                                                    textTransform: "uppercase",
                                                    fontSize: 20,
                                                    width: {
                                                        md: "60%",
                                                        xs: "100%",
                                                    },
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {blog.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    opacity: 0.9,
                                                    py: 3,
                                                }}
                                            >
                                                {blog.content}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    mt: 2,
                                                    mb: 4,
                                                }}
                                            >
                                                <Box>
                                                    <Typography
                                                        sx={{ fontSize: 15 }}
                                                    >
                                                        <strong>
                                                            @Author :
                                                        </strong>{" "}
                                                        {
                                                            blog?.author
                                                                ?.first_name
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        sx={{ fontSize: 15 }}
                                                    >
                                                        <strong>
                                                            Posted :
                                                        </strong>{" "}
                                                        {moment(
                                                            blog?.created_at
                                                        ).format(
                                                            "MMM Do YYYY, h:mm A"
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </SpacingContent>
                    )}
                    <Box>
                        <LatestBlogPost />
                    </Box>
                </PageLayout2>
            )}
        </>
    );
};

export default PostDetails;
