import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reduces
import blogsReducer from "../features/blogs/blogsSlice";
import helpCenterReducer from "../features/helpCenter/helpCenterSlice";

// => RTK reducers
import { blogsAPI } from "../../api/blogs/blogsAPI";
import { helpCenterAPI } from "../../api/helpCenter/helpCenterAPI";

export const store = configureStore({
    reducer: {
        [blogsAPI.reducerPath]: blogsAPI.reducer,
        [helpCenterAPI.reducerPath]: helpCenterAPI.reducer,
        blogsReducer,
        helpCenterReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
