import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../assets/utils/constants";
import { Send } from "@mui/icons-material";
import { Form, Formik } from "formik";
import CustomTextField from "../components/forms/CustomTextField";
import CustomMultlineTextField from "../components/forms/CustomMultlineTextField";
import CustomSubmitButton from "../components/forms/CustomSubmitButton";
import { useSendUsEmailMutation } from "../api/helpCenter/helpCenterAPI";
import * as Yup from "yup";
import useRTK from "../hooks/useRTK";

// ############# sendEmailValidationSchema ###################
const sendEmailValidationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().required(),
    subject: Yup.string().required(),
    message: Yup.string().required(),
});

const SendUsEMail = () => {
    // ############## CONST ##############
    const RTK = useRTK();

    // ############## RTK ##################
    const [
        sendMessage,
        { isLoading, isSuccess, isError, error, data: emailData },
    ] = useSendUsEmailMutation();
    // => Response
    RTK.useRTKResponse(isSuccess, isError, error, emailData);

    return (
        <div>
            <Box
                sx={{
                    borderRadius: 10,
                    width: "100%",
                    borderBottomRightRadius: 5,
                    overflow: "hidden",
                    boxShadow: `5px 5px 10px 0px ${colors.secondary}`,
                    borderBottom: `15px solid ${colors.primary}`,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.secondary})`,
                }}
            >
                <Box sx={{ width: "100%", mt: 1, pb: 5 }}>
                    <Typography
                        sx={{
                            fontSize: { md: 35, xs: 25 },
                            fontWeight: "bold",
                            p: 2,
                            px: { md: 4, xs: 2 },
                        }}
                    >
                        Leave us a message
                    </Typography>
                    <Box sx={{ px: { md: 4, xs: 2 } }}>
                        <Formik
                            initialValues={{
                                name: "",
                                email: "",
                                subject: "",
                                message: "",
                            }}
                            validationSchema={sendEmailValidationSchema}
                            onSubmit={(payload, { resetForm }) => {
                                sendMessage(payload);
                                resetForm();
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <Grid container columnSpacing={2}>
                                        <Grid item sm={6} xs={6}>
                                            <CustomTextField
                                                name="name"
                                                type={"text"}
                                                label={"Full name"}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                            <CustomTextField
                                                name="email"
                                                type={"email"}
                                                label={"Your email"}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CustomTextField
                                                name="subject"
                                                type={"text"}
                                                label={"Subject"}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CustomMultlineTextField
                                                name="message"
                                                type={"text"}
                                                label={"Message"}
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            sx={{
                                                mt: 2,
                                            }}
                                        >
                                            <CustomSubmitButton
                                                endIcon={<Send />}
                                                onClick={formik.handleSubmit}
                                                loading={isLoading}
                                            >
                                                Send message
                                            </CustomSubmitButton>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default SendUsEMail;
