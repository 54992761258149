import React from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import Image1 from '../assets/media/images/dsm.jpg';
import { colors } from '../assets/utils/constants';
import SpacingContent from './SpacingContent';
import Section from './Section';
import { ArrowRightAltTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const AboutUsHome = () => {
    const deviceWidth = useMediaQuery('(min-width: 768px)');
    return (
        <>
            <SpacingContent maxPadding={3}>
                <Section>
                    <Grid
                        container
                        justifyContent={'space-between'}
                        spacing={5}
                    >
                        <Grid item sm={6}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 45,
                                            fontWeight: 'bold',
                                            opacity: 0.9,
                                            pl: 1,
                                            background: `linear-gradient(90deg,  ${colors.secondary},rgba(23, 35, 60, .0))`,
                                        }}
                                    >
                                        Wazawa Max
                                    </Typography>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 17,
                                                opacity: 0.8,
                                                mt: 2,
                                                p: 2
                                            }}
                                        >
                                            Wazawa Max is a multifaceted Network
                                            with superlative opportunities that
                                            aims to maximize or completely
                                            utilize the available opportunities
                                            to generate sustained
                                            social-economic impact through
                                            employment, entrepreneurship,
                                            education, environmental
                                            sustainability, and economic
                                            equality in Tanzania.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: {
                                                md: 'none',
                                                xs: 'block',
                                            },
                                            mt: 4,
                                            borderRadius: { md: 150, xs: 25 },
                                            borderBottomLeftRadius: 15,
                                            borderTopRightRadius: {
                                                md: 100,
                                                xs: 50,
                                            },
                                            boxShadow: `5px 5px 30px 0px ${colors.secondary}`,
                                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                        }}
                                    >
                                        <img
                                            src={Image1}
                                            alt=""
                                            style={{
                                                height: deviceWidth ? 500 : 200,
                                                width: '100%',
                                                borderRadius: deviceWidth
                                                    ? 150
                                                    : 20,
                                                borderBottomLeftRadius: 15,
                                                borderTopRightRadius: 25,
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            'mt': { md: 8, xs: 4 },
                                            'display': 'flex',
                                            'justifyContent': 'space-between',
                                            '& .stats-con': {
                                                'width': '100%',
                                                'borderRadius': 2,
                                                'py': 1,
                                                'mx': 0.5,
                                                'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                                                '& .title': {
                                                    fontSize: {
                                                        md: 20,
                                                        xs: 16,
                                                    },
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    color: colors.primary,
                                                    opacity: 0.8,
                                                },
                                                '& .count': {
                                                    fontSize: {
                                                        md: 30,
                                                        xs: 25,
                                                    },
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                },
                                            },
                                        }}
                                    >
                                        <Box className="stats-con">
                                            <Typography
                                                className="count"
                                                sx={{ color: colors.warning }}
                                            >
                                                50+
                                            </Typography>
                                            <Typography className="title">
                                                Organizations
                                            </Typography>
                                        </Box>
                                        <Box className="stats-con">
                                            <Typography
                                                className="count"
                                                sx={{ color: colors.success }}
                                            >
                                                50+
                                            </Typography>
                                            <Typography className="title">
                                                Jobs vacancy
                                            </Typography>
                                        </Box>
                                        <Box className="stats-con">
                                            <Typography
                                                className="count"
                                                sx={{ color: colors.linkHover }}
                                            >
                                                50+
                                            </Typography>
                                            <Typography className="title">
                                                Fundi's
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    mt: { md: -6, xs: 'auto' },
                                }}
                            >
                                <Link to={'/about-us'}>
                                    <Button
                                        fullWidth
                                        endIcon={<ArrowRightAltTwoTone />}
                                        color="secondary"
                                        variant="contained"
                                        sx={{
                                            'fontSize': 17,
                                            'fontWeight': 'bold',
                                            'color': colors.primary,
                                            '& .arrow': {
                                                transition:
                                                    '.3s all ease-in-out',
                                            },
                                        }}
                                    >
                                        Know more about Wazawa Max
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={5.5}
                            sx={{
                                display: { md: 'block', xs: 'none' },
                                mt: 15,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    borderRadius: { md: 150, xs: 25 },
                                    borderBottomLeftRadius: 15,
                                    borderTopRightRadius: { md: 100, xs: 50 },
                                    boxShadow: `5px 5px 30px 0px ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                }}
                            >
                                <img
                                    src={Image1}
                                    alt=""
                                    style={{
                                        height: deviceWidth ? 500 : 200,
                                        width: '100%',
                                        borderRadius: deviceWidth ? 150 : 20,
                                        borderBottomLeftRadius: 15,
                                        borderTopRightRadius: 25,
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default AboutUsHome;
