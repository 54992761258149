import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import bannerBg from '../../assets/media/images/dsm.jpg';
import { colors } from '../../assets/utils/constants';
import SpacingContent from '../../components/SpacingContent';

const PageLayout2 = ({ head, subHead, children }) => {
    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            height: { md: 400, xs: 300 },
                            overflow: 'hidden',
                            position: 'relative',
                            backgroundPosition: 'center',
                            backgroundSize: '100% 600px',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${bannerBg})`,
                        }}
                    >
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                background: `linear-gradient(180deg, rgba(23, 35, 60, .4), ${colors.bgColor2})`,
                            }}
                        >
                            <SpacingContent maxPadding={2}>
                                <Grid container>
                                    <Grid item sm={5}>
                                        <Typography
                                            sx={{
                                                fontSize: { md: 35, xs: 20 },
                                                fontWeight: 'bold',
                                                textAlign: 'left',
                                                color: colors.primary,
                                                opacity: 0.9,
                                                textTransform: 'uppercase',
                                                position: 'relative',
                                            }}
                                        >
                                            {head}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                textAlign: 'left',
                                                color: colors.primary,
                                                opacity: 1,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {subHead}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </SpacingContent>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} sx={{ position: 'relative' }}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout2;
