import { ArrowRightAltTwoTone } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { strCapitalizeFirstChar } from '../../helpers/strHelper';

const BlogPostWidget = ({ blog }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 5,
                    overflow: "hidden",
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                }}
            >
                <Box>
                    <Avatar
                        src={blog.blog_image}
                        variant="square"
                        sx={{
                            height: 160,
                            width: "100%",
                            img: {
                                objectFit: "contain",
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        p: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                    }}
                >
                    <Typography
                        sx={{ fontSize: 17, fontWeight: "bold", pb: 1 }}
                    >
                        {strCapitalizeFirstChar(blog.title)}
                    </Typography>
                    <Typography noWrap sx={{ fontSize: 15, width: 300, mb: 1 }}>
                        {blog.content}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography sx={{ fontSize: 15 }}>
                                <strong>@Author :</strong>{" "}
                                {blog?.author?.first_name}
                            </Typography>
                            <Typography sx={{ fontSize: 15 }}>
                                <strong>Posted :</strong>{" "}
                                {moment(blog.created_at).format(
                                    "MMM Do YYYY, h:mm A"
                                )}
                            </Typography>
                        </Box>
                        <Link to={`/blog/${blog.id}`}>
                            <Button
                                size="small"
                                sx={{
                                    "& .arrow": {
                                        transition: ".3s all ease-in-out",
                                    },
                                    ":hover": {
                                        "& .arrow": {
                                            mr: -2,
                                            transition: ".3s all ease-in-out",
                                        },
                                    },
                                }}
                            >
                                <ArrowRightAltTwoTone
                                    className="arrow"
                                    sx={{ fontSize: 35 }}
                                />
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default BlogPostWidget;
