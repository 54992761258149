import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Section from './Section';
import SpacingContent from './SpacingContent';
import FrequenceAsked from './FrequenceAsked';
import { helpCenterSelector } from '../states/features/selectors';
import { useSelector } from 'react-redux';

const FAQs = () => {
    // ############# Redux State #############
    const { faqs } = useSelector(helpCenterSelector);

    return (
        <>
            <SpacingContent maxPadding={2}>
                <Section>
                    <Grid container spacing={5}>
                        <Grid item sm={12}>
                            <Box>
                                <Box>
                                    <Typography className="section-head">
                                        Frequency asked questions
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            mt: 0.5,
                                        }}
                                    >
                                        Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit.
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <FrequenceAsked faqs={faqs} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default FAQs;
