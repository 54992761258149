import React from "react";
import { Avatar, Box, Grid, Typography, styled } from "@mui/material";
import logo from "../assets/media/images/logo.png";
import Loader from "react-js-loader";
import { colors } from "../assets/utils/constants";

// ########## MUI Custom ############
const PageBgImageCon = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

const FullAppLoader = () => {
    return (
        <>
            <Box>
                <PageBgImageCon>
                    <Box
                        sx={{
                            position: "fixed",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: colors.primary,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            <Grid container justifyContent={"center"}>
                                <Grid item sm={2}>
                                    <Avatar
                                        variant="square"
                                        src={logo}
                                        sx={{
                                            height: 60,
                                            width: 60,
                                            img: {
                                                objectFit: "contain",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12} sx={{ mt: -1 }}>
                                    <Typography
                                        style={{
                                            fontSize: 30,
                                            textAlign: "center",
                                            color: colors.secondary,
                                        }}
                                    >
                                        Wazawa Max
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12} sx={{ mt: -5 }}>
                                    <Box>
                                        <Loader
                                            type="rectangular-ping"
                                            bgColor={colors.secondary}
                                            color={colors.secondary}
                                            size={190}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </PageBgImageCon>
            </Box>
        </>
    );
};

export default FullAppLoader;
