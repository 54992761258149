import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import bannerBg from '../../assets/media/images/dsm.jpg';
import { colors } from '../../assets/utils/constants';
import SpacingContent from '../../components/SpacingContent';
import Section from '../../components/Section';

const PageLayout = ({ head, subHead, sectionHd, sectionSubHd, children }) => {
    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            height: { md: 400, xs: 300 },
                            overflow: 'hidden',
                            position: 'relative',
                            backgroundPosition: 'center',
                            backgroundSize: '100% 600px',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${bannerBg})`,
                        }}
                    >
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                background: `linear-gradient(180deg, rgba(23, 35, 60, .4), ${colors.bgColor2})`,
                            }}
                        >
                            <SpacingContent maxPadding={2}>
                                <Grid container>
                                    <Grid item sm={5}>
                                        <Typography
                                            // sx={{
                                            //     fontSize: { md: 35, xs: 20 },
                                            //     fontWeight: 'bold',
                                            //     textAlign: 'left',
                                            //     color: colors.primary,
                                            //     opacity: 0.9,
                                            //     textTransform: 'uppercase',
                                            //     position: 'relative',
                                            // }}
                                            sx={{
                                                fontSize: { md: 30, xs: 20 },
                                                color: colors.primary,
                                                position: 'relative',
                                                border: `2px dotted ${colors.warning}`,
                                                textAlign: 'center',
                                                p: 2,
                                                mb: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary}) !important`,
                                                textTransform: 'uppercase',
                                                opacity: 0.7,
                                                borderRadius: 2,
                                            }}
                                        >
                                            {head}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                textAlign: 'left',
                                                color: colors.primary,
                                                opacity: 1,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {subHead}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </SpacingContent>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} sx={{ position: 'relative' }}>
                    <SpacingContent maxPadding={2}>
                        <Box
                            sx={{
                                mt: { md: -10, xs: -10 },
                                mb: { md: 5, xs: 5 },
                                borderRadius: { md: 10, xs: 5 },
                                overflow: 'hidden',
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                            }}
                        >
                            <Grid container spacing={4}>
                                <Grid item sm={12}>
                                    <Box>
                                        <SpacingContent maxPadding={2}>
                                            <Section>
                                                <Grid container spacing={5}>
                                                    <Grid item sm={12}>
                                                        {sectionHd && (
                                                            <Box>
                                                                <Box>
                                                                    <Typography className="section-head">
                                                                        {
                                                                            sectionHd
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        component={
                                                                            'div'
                                                                        }
                                                                        sx={{
                                                                            fontSize: 16,
                                                                            fontWeight:
                                                                                'bold',
                                                                            mt: 0.5,
                                                                            mb: 2,
                                                                        }}
                                                                    >
                                                                        {
                                                                            sectionSubHd
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                        {children}
                                                    </Grid>
                                                </Grid>
                                            </Section>
                                        </SpacingContent>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </SpacingContent>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout;
