import getEnv from '../../helpers/getEnv';

// => Dev base url
const adminDevBaseUrl = getEnv().REACT_APP_ADMIN_DEV_URL;
const orgDevBaseUrl = getEnv().REACT_APP_ORG_DEV_URL;

// => Pro base url
const adminProBaseUrl = getEnv().REACT_APP_ADMIN_PRODUCTION_URL;
const orgProBaseUrl = getEnv().REACT_APP_ORG_PRODUCTION_URL;

// => Get Admin baseURL
const getAdminBaseURL = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === 'pro') {
        return adminProBaseUrl;
    }
    return adminDevBaseUrl;
};
// => Get Org baseURL
const getOrgBaseURL = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === 'pro') {
        return orgProBaseUrl;
    }
    return orgDevBaseUrl;
};

export const adminBaseURL = getAdminBaseURL();
export const orgBaseURL = getOrgBaseURL();
