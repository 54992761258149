import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { helpCenterSelector } from "../states/features/selectors";
import { colors } from "../assets/utils/constants";
import SpacingContent from "./SpacingContent";
import Section from "./Section";

const MapLocation = () => {
    // ############ Redux state ##############
    const { contacts } = useSelector(helpCenterSelector);

    return (
        <div>
            <SpacingContent maxPadding={3}>
                <Section>
                    {isEmpty(contacts) ? (
                        <></>
                    ) : (
                        <Grid container spacing={4}>
                            <Grid item sm={7}>
                                <Box
                                    sx={{
                                        height: 350,
                                        borderRadius: 5,
                                        overflow: "hidden",
                                        border: `15px solid ${colors.bgColor3}`,
                                    }}
                                >
                                    <iframe
                                        src={contacts[0]?.map_url}
                                        width="100%"
                                        height="100%"
                                        title="Location"
                                        style={{ border: 0 }}
                                        aria-hidden="false"
                                    ></iframe>
                                </Box>
                            </Grid>
                            <Grid item sm={5} xs={12}>
                                <a
                                    href={contacts[0]?.map_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Box
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                fontSize: 20,
                                                color: colors.info,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                                opacity: 0.8,
                                            }}
                                        >
                                            <LocationOn
                                                sx={{
                                                    fontSize: 60,
                                                    color: colors.warning,
                                                    opacity: 0.8,
                                                }}
                                            />
                                            {contacts[0]?.address}
                                        </Typography>
                                    </Box>
                                </a>
                            </Grid>
                        </Grid>
                    )}
                </Section>
            </SpacingContent>
        </div>
    );
};

export default MapLocation;
