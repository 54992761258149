import React from 'react';
import { Box, Typography } from '@mui/material';
import PageLayout from './layouts/PageLayout';
import { colors } from '../assets/utils/constants';

const Terms = () => {
    const terms = [
        {
            id: 1,
            term: 'Purpose of the Network',
            description:
                'Wazawa Max Network is designed to provide a diverse array of opportunities and services to its stakeholders, including but not limited to Talent Tap Network, Max Skill Nexus, SkillAttach360, Career Crafters Network, Market Mingle, and PromoConnect360. These opportunities aim to facilitate collaboration, growth, and success in various fields.',
        },
        {
            id: 2,
            term: 'Eligibility',
            description:
                'Stakeholders must comply with all applicable laws and regulations and meet the eligibility criteria specified for each service within the network. Users must be at least 18 years old or the legal age of majority in their jurisdiction to access certain features.',
        },
        {
            id: 3,
            term: 'User Responsibilities',
            description:
                'Stakeholders are responsible for maintaining the confidentiality of their account credentials and agree to provide accurate, current, and complete information during registration. Stakeholders shall use the network in a responsible and lawful manner, respecting the rights and privacy of others.',
        },
        {
            id: 4,
            term: 'Acceptable Use',
            description:
                "Stakeholders agree not to engage in any activities that violate the intellectual property rights of Wazawa Max or third parties, transmit unlawful or harmful content, interfere with the network's operation, impersonate others, or engage in any activities that may harm, exploit, or endanger others.",
        },
        {
            id: 5,
            term: 'Termination',
            description:
                'Wazawa Max reserves the right to terminate or suspend access to the network for any stakeholder who violates these Agreements or engages in harmful or unlawful activities. Stakeholders may terminate their accounts by discontinuing their use of the network.',
        },
        {
            id: 6,
            term: 'Disclaimers',
            description:
                'Wazawa Max Network provides its services "as is" and makes no warranties, express or implied, regarding the quality, accuracy, or availability of its services.',
        },
        {
            id: 7,
            term: 'Limitation of Liability',
            description:
                'Wazawa Max and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from the use of our network.',
        },
        {
            id: 8,
            term: 'Governing Law',
            description:
                'These Agreements are governed by Tanzanian law, and any disputes arising from or related to these Agreements shall be subject to the exclusive jurisdiction of Tanzanian courts.',
        },
        {
            id: 9,
            term: 'Changes to Agreements',
            description:
                'Wazawa Max reserves the right to modify or revise these Agreements at any time. Continued use of the network after modifications constitutes acceptance of the new terms.',
        },
        {
            id: 10,
            term: 'Privacy',
            description:
                'The privacy of our stakeholders is important to us. Our Privacy Policy outlines how we collect, use, and protect personal information. Stakeholders are encouraged to review our Privacy Policy, which is an integral part of these Agreements.',
        },
    ];
    return (
        <>
            <PageLayout
                head={'WAZAWA MAX TERMS and AGREEMENTS '}
                sectionHd={'Terms And Agreements'}
                sectionSubHd={
                    <Box sx={{ mb: 5 }}>
                        <Typography
                            sx={{
                                fontSize: 16,
                                opacity: 0.7,
                                color: colors.warning,
                            }}
                        >
                            Welcome to Wazawa Max Network, your gateway to a
                            multifaceted platform brimming with superlative
                            opportunities. Our mission is to harness and
                            maximize available resources, fostering sustained
                            social-economic impact in Tanzania. We're dedicated
                            to promoting employment, entrepreneurship,
                            education, environmental sustainability, and
                            economic equality. Within our network, you'll
                            discover a multitude of avenues for growth and
                            collaboration that can transform lives and
                            communities.
                        </Typography>
                        <Typography
                            sx={{
                                pt: 4,
                                pb: 1,
                                fontSize: 20,
                                fontWeight: 'bold',
                                opacity: 0.6,
                                textDecoration: 'underline',
                            }}
                        >
                            General Terms and Agreements for Wazawa Max{' '}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 16,
                                opacity: 0.6,
                            }}
                        >
                            These General Terms and Agreements ("Agreements")
                            constitute a binding contract between Wazawa Max
                            Network ("Wazawa Max," "we," "us," or "our") and all
                            stakeholders participating in our network, including
                            but not limited to users, individuals, groups,
                            firms, companies, professionals, organizations, and
                            employers (collectively, "Stakeholders"). By
                            accessing or using any of the Wazawa Max Network's
                            services, you acknowledge and agree to abide by
                            these Agreements. If you do not agree with these
                            terms, please refrain from using our services.
                        </Typography>
                    </Box>
                }
            >
                <Box sx={{ mt: 2 }}>
                    {terms.map((term, index) => (
                        <Box key={index} sx={{ mb: 5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 35,
                                        width: 35,
                                        justifyContent: 'center',
                                        border: `1px dotted ${colors.warning}`,
                                        borderRadius: 2,
                                        mr: 1,
                                        color: colors.warning,
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {++index}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        opacity: 0.6,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {term.term}
                                </Typography>
                            </Box>
                            <Typography sx={{ fontSize: 16, opacity: 0.8 }}>
                                {term.description}
                            </Typography>
                        </Box>
                    ))}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                opacity: 0.6,
                                textAlign: 'center',
                            }}
                        >
                            By using Wazawa Max Network, you acknowledge that
                            you have read, understood, and agreed to these
                            General Terms and Agreements. If you have any
                            questions or concerns, please contact us at <br />
                            <Typography
                                sx={{ color: 'blue !important' }}
                                component={'span'}
                            >
                                <a href="mailto:info@wazawamax.co.tz">
                                    info@wazawamax.co.tz
                                </a>
                            </Typography>
                        </Typography>
                        <Typography
                            sx={{
                                pt: 4,
                                pb: 1,
                                fontSize: {md: 22, xs: 18},
                                fontWeight: 'bold',
                                opacity: 0.6,
                                color: colors.warning,
                                textAlign: 'center',
                            }}
                        >
                            Last Updated: 10th September 2023
                        </Typography>
                    </Box>
                </Box>
            </PageLayout>
        </>
    );
};

export default Terms;
