import React from 'react';
import { Box, Grid } from '@mui/material';
import Loader from 'react-js-loader';
import { colors } from '../assets/utils/constants';

const PageLoader = () => {
    return (
        <>
            <Grid container>
                <Grid
                    item
                    sm={12}
                    sx={{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        position: 'fixed',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                    }}
                >
                    <Box>
                        <Loader
                            type="bubble-ping"
                            bgColor={colors.primary}
                            color={colors.primary}
                            size={100}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLoader;
