import { useEffect } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { useDispatch } from "react-redux";
import { useGetBlogsQuery } from "../api/blogs/blogsAPI";
import { setBlogs } from "../states/features/blogs/blogsSlice";
import {
    useGetContactsQuery,
    useGetFAQQuery,
    useGetSocialNetworksQuery,
} from "../api/helpCenter/helpCenterAPI";
import {
    setContacts,
    setFaqs,
    setSocialNetworks,
} from "../states/features/helpCenter/helpCenterSlice";

const useRTK = () => {
    // => RTK Response
    const useRTKResponse = (isSuccess, isError, error, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    setTimeout(() => {
                        successNotify(getRTKSuccessMessage(data));
                    }, 500);
                }
                if (isError) {
                    errorNotify(getRTKErrorMessage(error));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, actions, data]);
    };

    // => RTK Fetch
    const useRTKFetch = (isSuccess, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions(getRTKResponseData(data));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, data, actions]);
    };

    // => RTK main data
    const useRTKMainData = () => {
        // => CONST
        const dispatch = useDispatch();

        // ################# Contacts #################
        const {
            isLoading: contactLoading,
            isSuccess: contactSuccess,
            data: contactsData,
        } = useGetContactsQuery();
        const rtkContactActions = (data) => {
            dispatch(setContacts(data));
        };
        useRTKFetch(contactSuccess, contactsData, rtkContactActions);

        // ################# Social nets #################
        const {
            isLoading: socialNetLoading,
            isSuccess: socialNetSuccess,
            data: socialNetsData,
        } = useGetSocialNetworksQuery();
        const rtkSocialNetActions = (data) => {
            dispatch(setSocialNetworks(data));
        };
        useRTKFetch(socialNetSuccess, socialNetsData, rtkSocialNetActions);

        // ################# Faq #################
        const {
            isLoading: faqLoading,
            isSuccess: faqSuccess,
            data: faqsData,
        } = useGetFAQQuery();
        const rtkFaqActions = (data) => {
            dispatch(setFaqs(data));
        };
        useRTKFetch(faqSuccess, faqsData, rtkFaqActions);

        // ################# Blogs #################
        const {
            isLoading: blogLoading,
            isSuccess: blogSuccess,
            data: blogsData,
        } = useGetBlogsQuery();
        const rtkActions = (data) => {
            dispatch(setBlogs(data));
        };
        useRTKFetch(blogSuccess, blogsData, rtkActions);

        return blogLoading
            ? blogLoading
            : contactLoading
            ? contactLoading
            : socialNetLoading
            ? socialNetLoading
            : faqLoading
            ? faqLoading
            : false;
    };

    return {
        useRTKFetch,
        useRTKResponse,
        useRTKMainData,
    };
};

export default useRTK;
