import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar";
import { colors } from "../../assets/utils/constants";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import useRTK from "../../hooks/useRTK";
import HotToastfy from "../../components/HotToastfy";
import FullAppLoader from "../../components/FullAppLoader";

const BodyBackground = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

const AppLayout = ({ children }) => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    // ############## CONST ############
    const RTK = useRTK();

    // ############## RTK ############
    RTK.useRTKMainData();

    return (
        <Box
            sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                bottom: "100%",
                right: 0,
                left: 0,
            }}
        >
            {/* ############## content ############## */}
            {RTK.useRTKMainData() ? (
                <FullAppLoader />
            ) : (
                <>
                    {/* ############## Tostfy ############## */}
                    <HotToastfy />

                    <Box component="main" sx={{ flexGrow: 1 }}>
                        <BodyBackground />
                        {/* ############## Navbar ############## */}
                        <Navbar />

                        {children}

                        {/* ############## Navbar ############## */}
                        <Footer />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AppLayout;
