import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contacts: {},
    socialNetworks: [],
    faqs: [],
};

const helpCenterSlice = createSlice({
    name: "helpCenterSlice",
    initialState,
    reducers: {
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
        setSocialNetworks: (state, action) => {
            state.socialNetworks = action.payload;
        },
        setFaqs: (state, action) => {
            state.faqs = action.payload;
        },
    },
});

export const { setContacts, setSocialNetworks, setFaqs } =
    helpCenterSlice.actions;
export default helpCenterSlice.reducer;
