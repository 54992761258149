import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { colors } from '../assets/utils/constants';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
    'border': `1px solid ${colors.bgColor5}`,
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
    'flexDirection': 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FrequenceAsked = ({faqs}) => {
    const [expanded, setExpanded] = React.useState('panel-1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            {faqs?.map((question, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === `panel-${++index}`}
                    onChange={handleChange(`panel-${index}`)}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{ fontWeight: "bold", opacity: 0.8 }}
                            >
                                {question.question}
                            </Typography>
                            <Typography
                                sx={{
                                    height: 30,
                                    width: 30,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 50,
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                                }}
                            >
                                {index}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{question.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default FrequenceAsked;
