import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { colors } from "../assets/utils/constants";
import { Forward } from "@mui/icons-material";
import SocialLinkWidget from "./widgets/SocialLinkWidget";
import { helpCenterSelector } from "../states/features/selectors";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const SocialAndSubscribe = () => {
    const { socialNetworks } = useSelector(helpCenterSelector);
    return (
        <>
            <Box
                sx={{
                    mt: { md: 10, xs: 5 },
                    py: 4,
                    px: 2,
                    bgcolor: colors.bgColor3,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { md: 40, xs: 30, opacity: 0.8 },
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Get in touch with us
                    </Typography>
                    <Formik>
                        {(formik) => (
                            <Form>
                                <Grid container justifyContent={"center"}>
                                    <Grid item sm={4} xs={12}>
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    md: 16,
                                                    xs: 14,
                                                    opacity: 0.8,
                                                },
                                                textAlign: "center",
                                            }}
                                        >
                                            Subscribe for an instant update
                                            about Wazawa Max
                                        </Typography>
                                        <Box
                                            sx={{
                                                mt: 2,
                                                display: "flex",
                                                width: "100%",
                                                "& .MuiInputBase-root": {
                                                    borderRadius: 0,
                                                    height: 50,
                                                    borderBottomLeftRadius: `20px !important`,
                                                },
                                                "& .MuiButtonBase-root": {
                                                    borderRadius: `0 !important`,
                                                    borderTopRightRadius: `20px !important`,
                                                },
                                            }}
                                        >
                                            <TextField
                                                label={"Provide your email"}
                                                fullWidth
                                                size="small"
                                            />
                                            <Button
                                                variant="contained"
                                                endIcon={<Forward />}
                                                sx={{
                                                    px: 4,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Subscribe
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                    <Grid
                        container
                        justifyContent={"center"}
                        sx={{ mt: { md: 5, xs: 3 } }}
                    >
                        <Grid item sm={8} xs={12}>
                            <Grid container spacing={2}>
                                {isEmpty(socialNetworks) ? (
                                    <></>
                                ) : (
                                    socialNetworks?.map((link, index) => (
                                        <Grid item sm={2} xs={6} key={link.id}>
                                            <SocialLinkWidget link={link} />
                                        </Grid>
                                    ))
                                )}
                                {/* {socialLinks?.map((link) => (
                                        <Grid item sm={2} xs={6} key={link.id}>
                                            <SocialLinkWidget link={link} />
                                        </Grid>
                                    ))} */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default SocialAndSubscribe;
