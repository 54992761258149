import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/constants";

const ContactWidget = ({ contact }) => {
    return (
        <>
            <a href={contact.url} target="_blank" rel="noopener noreferrer">
                <Box
                    sx={{
                        height: { md: 150, xs: 130 },
                        borderRadius: 5,
                        borderBottomRightRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        borderBottom: `3px solid ${colors.primary}`,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                        transition: `.3s all ease-in-out`,
                        ":hover": {
                            mt: -1,
                            transition: `.3s all ease-in-out`,
                            "& .contact": {
                                color: colors.warning,
                                transition: `.3s all ease-in-out`,
                            },
                            "& .icon": {
                                color: colors.warning,
                                transition: `.3s all ease-in-out`,
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            "& .title": {
                                fontSize: 20,
                                textAlign: "center",
                                fontWeight: "bold",
                                display: { md: "block", xs: "none" },
                            },
                            "& .contact": {
                                fontSize: { md: 16, xs: 12 },
                                textAlign: "center",
                                transition: `.3s all ease-in-out`,
                                px: 2,
                            },
                            "& .icon": {
                                fontSize: { md: 30, xs: 25 },
                                transition: `.3s all ease-in-out`,
                            },
                        }}
                    >
                        <Typography sx={{ textAlign: "center" }}>
                            {contact.icon}
                        </Typography>
                        <Typography className="title">
                            {contact.title}
                        </Typography>
                        <Typography className="contact" noWrap>
                            {contact.contact}
                        </Typography>
                    </Box>
                </Box>
            </a>
        </>
    );
};

export default ContactWidget;
