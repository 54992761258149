import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import SpacingContent from '../components/SpacingContent';
import { colors } from '../assets/utils/constants';
import BlogPostWidget from '../components/widgets/BlogPostWidget';
import Section from '../components/Section';
import PageLayout2 from './layouts/PageLayout2';
import { useSelector } from 'react-redux';
import { blogsSelector } from '../states/features/selectors';
import { isEmpty, shuffle, take } from 'lodash';
import moment from 'moment';
import NoContent from '../components/NoContent';

const Blog = () => {
    const deviceWidth = useMediaQuery('(min-width: 768px)');
    // ############## Redux state ############
    const { blogs } = useSelector(blogsSelector);
    const overviewBlog = take(shuffle(blogs), 1)[0];
    return (
        <>
            <PageLayout2
                head={'Wazawa Max latest news'}
                subHead={
                    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, sequi neque veritatis earum, nihil culpa.'
                }
            >
                <SpacingContent maxPadding={2}>
                    {isEmpty(blogs) ? (
                        <NoContent message={'No blog posted'} height={300} />
                    ) : (
                        <>
                            <Box
                                sx={{
                                    mt: { md: -10, xs: -10 },
                                    mb: 10,
                                    borderRadius: { md: 15, xs: 5 },
                                    overflow: 'hidden',
                                    pb: 4,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                                }}
                            >
                                <Grid container spacing={4}>
                                    <Grid item sm={5} xs={12}>
                                        <Box
                                            sx={{
                                                height: { md: 400, xs: 200 },
                                                p: 2,
                                            }}
                                        >
                                            <img
                                                src={overviewBlog.blog_image}
                                                alt=""
                                                style={{
                                                    width: '100%',
                                                    borderRadius: deviceWidth
                                                        ? 50
                                                        : 25,
                                                    height: deviceWidth
                                                        ? 400
                                                        : 200,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={7} xs={12}>
                                        <Box sx={{ p: 2 }}>
                                            <Typography
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    fontSize: 20,
                                                    width: {
                                                        md: '60%',
                                                        xs: '100%',
                                                    },
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {overviewBlog.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    opacity: 0.9,
                                                    py: 3,
                                                }}
                                            >
                                                {overviewBlog.content}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    mt: 2,
                                                    mb: 4,
                                                }}
                                            >
                                                <Typography
                                                    sx={{ fontSize: 15 }}
                                                >
                                                    <strong>Posted:</strong>{' '}
                                                    {moment(
                                                        overviewBlog.created_at,
                                                    ).format('MMM Do YYYY')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                sx={{
                                    mb: 10,
                                    width: '100%',
                                }}
                            >
                                <Section>
                                    <Box sx={{ mb: 4 }}>
                                        <Typography className="section-head">
                                            Latest blog post
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                                mt: 0.5,
                                            }}
                                        >
                                            Get update on every step we take.
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                display: 'flex',
                                                width: {
                                                    md: '50%',
                                                    xs: '100%',
                                                },
                                            }}
                                        >
                                            Lorem ipsum, dolor sit amet
                                            consectetur adipisicing elit.
                                            Pariatur dicta, et a id corporis
                                            nostrum veritatis necessitatibus
                                            asperiores maxime, consequatur
                                            maiores est corrupti exercitationem
                                            illo vitae itaque eius mollitia
                                            nisi.
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={4}>
                                        {blogs.map((blog) => (
                                            <Grid
                                                item
                                                sm={4}
                                                xs={12}
                                                key={blog.id}
                                            >
                                                <BlogPostWidget blog={blog} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Section>
                            </Box>
                        </>
                    )}
                </SpacingContent>
            </PageLayout2>
        </>
    );
};

export default Blog;
