import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Section from './Section';
import { Grid } from '@mui/material';
import { colors } from '../assets/utils/constants';

const steps = [
    {
        label: 'Mission Alignment:',
        description: `Wazawa Max exists to make connections and opportunities between students, training institutions, employers, job seekers, business owners, supply entities, and tenderers. These ties are essential for assisting people and organizations in achieving their aspirations.`,
    },
    {
        label: 'Empowering Dreams:',
        description:
            'Success today frequently starts with a dream. A lot of people dream—including students, jobseekers, business owners, and entrepreneurs—of rewarding professions, growing their companies, and securing deals. In order to make these ambitions a reality, Wazawa Max Network is essential.',
    },
    {
        label: 'The Power of Connectivity:',
        description: `We establish a network where dreams can take root by bringing diverse stakeholders together. Students can find best work opportunities and training. Employers looking for certain talents can connect with job seekers. Target clients are accessible to business owners. New tender opportunities can be found by businesses. Wazawa Max is, in essence, the link that connects dreams to actualization.`,
    },
    {
        label: 'Striving for Excellence:',
        description: `Wazawa Max's commitment to excellence is also reflected in "Connecting Dreams to Reality." We don't just help people connect; we also ensure that these connections are worthwhile and productive. We want to empower and maximize the potential of these connections rather than merely connecting.`,
    },
    {
        label: 'Customer-Centric Approach:',
        description: `Wazawa Max's commitment to its customers is reinforced by this slogan. We realize that the success of people who utilize our network is correlated with our own. We develop trust, loyalty, and enduring relationships by helping them make their dreams turn into reality. In conclusion, "Connecting Dreams to Reality" is more than simply a catchy tagline; it embodies Wazawa Max's mission, our passion to quality, and our commitment to enabling people and organizations to realize their dreams. It embodies the fundamental principles and objectives of Wazawa Max and conveys a message of hope and a guarantee of concrete outcomes. More than just a catchy phrase, "Connecting Dreams to Reality" embodies the essence of Wazawa Max. It stands for our purpose, commitment to excellence, and passion to supporting people and organizations in achieving their goals. The following Wazawa Max Opportunities are guided by this philosophy.`,
    },
];

const Slogan = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{}}>
            <Section>
                <Grid
                    container
                    spacing={{ md: 5, xs: 2 }}
                    sx={{ mt: { md: '', xs: 0 } }}
                >
                    <Grid item sm={8} xs={12}>
                        <Box>
                            <Box>
                                <Typography className="section-head">
                                    “Connecting dreams to reality”
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        mt: 0.5,
                                    }}
                                >
                                    The slogan "Connecting Dreams to Reality"
                                    encapsulates the core mission and value
                                    proposition of the Wazawa Max platform.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                <Box>
                                    <Typography
                                        sx={{
                                            border: `2px solid ${colors.bgColor5}`,
                                            width: 250,
                                            textAlign: 'center',
                                            p: 1,
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary}) !important`,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {step.label}
                                    </Typography>
                                </Box>
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1
                                                ? 'Finish'
                                                : 'Continue'}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Button
                        fullWidth
                        onClick={handleReset}
                        sx={{
                            mt: 1,
                            mr: 1,
                            fontSize: { md: 35, xs: 25 },
                            py: 3,
                            px: { md: 3, xs: 1.5 },
                            fontWeight: 'bold',
                            opacity: 0.8,
                            lineHeight: 1,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary}) !important`,
                        }}
                    >
                        “Connecting dreams to reality”
                    </Button>
                )}
            </Section>
        </Box>
    );
};

export default Slogan;
