import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

// -> css imports
import './assets/sass/App.scss';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';

// -> component imports
import App from './App';
import { store } from './states/stores/rootStore';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <CssBaseline />
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
);
