import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { colors } from '../assets/utils/constants';
import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import SpacingContent from '../components/SpacingContent';
import logo from '../assets/media/images/logo.png';

//* ################# CATEGORY CARD ###################
const CategoryCard = styled(Grid)(() => ({
    'background': colors.primary,
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'borderRadius': 5,
    'flexDirection': 'column',
    '& .button': {
        color: colors.secondary,
        flexDirection: 'column',
        height: 100,
        width: '100%',
    },
}));

//*################# GET STARTED BUTTON ###########
const GetStartedLink = styled(Button)(({ theme }) => ({
    'transition': 'all ease-in-out .3s',
    '.text': {
        fontSize: 18,
    },
    '.name': {
        fontSize: 22,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    '.icon': {
        position: 'absolute',
        right: 30,
        fontSize: 33,
        transition: 'all ease-in-out .3s',
    },
    '&:hover': {
        '.icon': {
            right: 20,
        },
    },
    [theme.breakpoints.down('md')]: {
        'background': colors.secondary,
        '.text': {
            fontSize: 14,
            color: colors.primary,
        },
        '.name': {
            fontSize: 14,
            color: colors.primary,
        },
        '.icon': {
            transition: 'all ease-in-out .3s',
            color: colors.primary,
            display: 'none',
        },
    },
}));

//! ############### MAIN FUNCTION ###################
const Login = () => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                <SpacingContent maxPadding={2}>
                    <Grid
                        container
                        justifyContent={'center'}
                        sx={{ mb: 5, display: { md: 'none' } }}
                    >
                        <Grid item sm={2} xs={4}>
                            <img
                                src={logo}
                                alt=""
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Typography
                                style={{
                                    fontSize: 50,
                                    textAlign: 'center',
                                    color: colors.secondary,
                                }}
                            >
                                Wazawa Max
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'space-around'} spacing={2}>
                        <CategoryCard item sm={5} xs={6}>
                            <a
                                href={`${process.env.REACT_APP_ORG_URL}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ width: '100%' }}
                            >
                                <GetStartedLink className="button">
                                    <Typography className="text">
                                        Login as
                                    </Typography>
                                    <Typography className="name">
                                        Organization
                                    </Typography>
                                    <ArrowRightAltIcon className="icon" />
                                </GetStartedLink>
                            </a>
                        </CategoryCard>
                        <CategoryCard item sm={5} xs={6}>
                            <a
                                href={`${process.env.REACT_APP_STUDENT_URL}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ width: '100%' }}
                            >
                                <GetStartedLink className="button">
                                    <Typography className="text">
                                        Login as
                                    </Typography>
                                    <Typography className="name">
                                        Student
                                    </Typography>
                                    <ArrowRightAltIcon className="icon" />
                                </GetStartedLink>
                            </a>
                        </CategoryCard>

                        {/* ######### SIGN UP ######### */}
                        <Grid
                            item
                            sm={10}
                            md={10}
                            xs={10}
                            sx={{
                                mt: { md: 10, xs: 5 },
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                OR
                            </Typography>
                            <Link to={'/get-started'}>
                                <Typography
                                    marginTop={2}
                                    sx={{
                                        color: {
                                            md: colors.primary,
                                            xs: colors.secondary,
                                        },
                                    }}
                                >
                                    Do not have an acount?{' '}
                                    <strong>get started now....</strong>
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid
                            item
                            sm={10}
                            md={10}
                            xs={10}
                            sx={{
                                mt: { md: 10, xs: 5 },
                                textAlign: 'center',
                            }}
                        >
                            <Link to={'/'}>
                                <Button
                                    variant="outlined"
                                    startIcon={<ArrowCircleLeftOutlined />}
                                    sx={{
                                        color: {
                                            md: colors.primary,
                                            xs: colors.secondary,
                                        },
                                    }}
                                >
                                    Back to home
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </SpacingContent>
            </Box>
        </React.Fragment>
    );
};

export default Login;
