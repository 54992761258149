// -> get all
const GET_ALL = '/blogs/published';

// -> get one
const GET_ONE = '/blogs/published';

export const blogsEndpoints = {
    GET_ALL,
    GET_ONE,
};
