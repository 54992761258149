import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from '../pages/About';
import Blog from '../pages/Blog';
import ForOrganizations from '../pages/ForOrganizations';
import ForStudents from '../pages/ForStudents';
import Help from '../pages/Help';
import Homepage from '../pages/Homepage';
import AppLayout from '../pages/layouts/AppLayout';
import Login from '../pages/Login';
import Policy from '../pages/Policy';
import ServiceDetails from '../pages/services/ServiceDetails';
import Services from '../pages/services/Services';
import Terms from '../pages/Terms';
import AuthLayout from '../pages/layouts/AuthLayout';
import GetStarted from '../pages/GetStarted';
import PostDetails from '../pages/PostDetails';
import FaqPage from '../pages/FaqPage';

const HomePageElement = () => (
    <AppLayout>
        <Homepage />
    </AppLayout>
);
const AboutUsElement = () => (
    <AppLayout>
        <About />
    </AppLayout>
);
const BlogElement = () => (
    <AppLayout>
        <Blog />
    </AppLayout>
);
const PostDetailsElement = () => (
    <AppLayout>
        <PostDetails />
    </AppLayout>
);
const HelpCenterElement = () => (
    <AppLayout>
        <Help />
    </AppLayout>
);
const ServicesElement = () => (
    <AppLayout>
        <Services />
    </AppLayout>
);
const ServiceDetailsElement = () => (
    <AppLayout>
        <ServiceDetails />
    </AppLayout>
);
const LoginElement = () => (
    <AuthLayout>
        <Login />
    </AuthLayout>
);
const GetStartedElement = () => (
    <AuthLayout>
        <GetStarted />
    </AuthLayout>
);
const ForOrganizationsElement = () => (
    <AppLayout>
        <ForOrganizations />
    </AppLayout>
);
const ForStudentsElement = () => (
    <AppLayout>
        <ForStudents />
    </AppLayout>
);
const TermsElement = () => (
    <AppLayout>
        <Terms />
    </AppLayout>
);
const PolicyElement = () => (
    <AppLayout>
        <Policy />
    </AppLayout>
);
const FaqElement = () => (
    <AppLayout>
        <FaqPage />
    </AppLayout>
);

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePageElement />} />
            <Route path="/about-us" element={<AboutUsElement />} />
            <Route path="/blog" element={<BlogElement />} />
            <Route path="/blog/:post_id" element={<PostDetailsElement />} />
            <Route path="/help-center" element={<HelpCenterElement />} />
            <Route path="/services" element={<ServicesElement />} />
            <Route
                path="/services/details"
                element={<ServiceDetailsElement />}
            />
            <Route path="/login" element={<LoginElement />} />
            <Route path="/get-started" element={<GetStartedElement />} />
            <Route
                path="/for-organizations"
                element={<ForOrganizationsElement />}
            />
            <Route path="/for-students" element={<ForStudentsElement />} />
            <Route path="/terms" element={<TermsElement />} />
            <Route path="/policy" element={<PolicyElement />} />
            <Route path="/faq" element={<FaqElement />} />
        </Routes>
    );
};

export default AppRoutes;
