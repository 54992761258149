import { ThemeProvider } from '@mui/material/styles';

// ======= component imports =============
import { primaryTheme } from './assets/utils/constants';
import AppRoutes from './routes/App.routes';

const App = () => {
    return (
        <div className="App">
            <ThemeProvider theme={primaryTheme}>
                <AppRoutes />
            </ThemeProvider>
        </div>
    );
};

export default App;
