import React from 'react';
import { Box, Typography } from '@mui/material';

const NoContent = ({ message, children, height }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {message ? (
                    <>
                        <Typography sx={{fontSize: 18,fontWeight: 'bold', opacity: .8}}>{message}</Typography>
                    </>
                ) : (
                    { children }
                )}
            </Box>
        </React.Fragment>
    );
};

export default NoContent;
