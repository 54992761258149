import * as React from "react";

import OurServices from "../components/OurServices";
import MobApp from "../components/MobApp";
import ForOrganization from "../components/ForOrganization";
import Banner from "../components/Banner";
import LatestBlogPost from '../components/LatestBlogPost';
import SocialAndSubscribe from "../components/SocialAndSubscribe";
import AboutUsHome from "../components/AboutUsHome";
import MapLocation from "../components/MapLocation";

// !################# MAIN FUNC ##################
function Homepage() {
    return (
        <React.Fragment>
            {/* ################# Banner ############### */}
            <Banner />

            {/* ################# ForORG ############### */}
            <ForOrganization />

            {/* ################# Banner ############### */}
            <AboutUsHome />

            {/* ################# SERVICES ############### */}
            <OurServices />

            {/* ################# MOB APP ############### */}
            <MobApp />

            {/* ################# BLOG POST ############### */}
            <LatestBlogPost />

            {/* ################# Social net and subscribed ############### */}
            <SocialAndSubscribe />

            {/* ################# Location address ############### */}
            <MapLocation />
        </React.Fragment>
    );
}

export default Homepage;
