import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/media/images/logo.png';
import { colors } from '../assets/utils/constants';
import SpacingContent from './SpacingContent';

const Footer = () => {
    const rightNavItems = [
        {
            id: 1,
            name: 'Terms and conditions',
            link: '/terms',
        },
        {
            id: 2,
            name: 'Privacy policy',
            link: '/policy',
        },
        {
            id: 3,
            name: "FAQ's",
            link: '/faq',
        },
    ];
    return (
        <>
            <SpacingContent bgcolor={colors.primary} maxPadding={2}>
                <Grid container justifyContent={'center'}>
                    <Grid item sm={12} xs={12}>
                        <Box
                            sx={{
                                height: { md: 80, xs: '100%' },
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Box sx={{ width: '100%' }}>
                                <Link to="/">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src={logo}
                                            alt="logo"
                                            style={{
                                                padding: 2,
                                                paddingLeft: 4,
                                                paddingRight: 4,
                                                borderRadius: 7,
                                                border: `2px solid ${colors.secondary}`,
                                                width: 30,
                                                height: 30,
                                                marginRight: 5,
                                                img: {
                                                    objectFit: 'contain',
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: colors.secondary,
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Wazawa Max
                                        </Typography>
                                    </Box>
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {rightNavItems.map((item) => (
                                    <Link key={item.id} to={item.link}>
                                        <Button
                                            sx={{
                                                textTransform: 'capitalize',
                                                fontSize: 13,
                                                color: colors.bgColor3,
                                            }}
                                        >
                                            {item.name}
                                        </Button>
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </SpacingContent>
        </>
    );
};

export default Footer;
